import {
  useGetCurrentUserQuery,
  useGetUserQuery,
  useGetUserRolesQuery,
  useUpdateUserMutation,
  useGetSpokenLanguageListQuery,
} from 'api/users';
import Avatar from 'components/base-components/Avatar';
import Button from 'components/base-components/Button';
import Card from 'components/base-components/Card';
import DatePicker from 'components/base-components/DatePicker';
import Input from 'components/base-components/Input';
import SelectBox from 'components/base-components/SelectBox';
import AvatarModal from 'components/shared-components/AvatarModal';
import useCustomNavigate from 'hooks/useCustomNavigate';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import { toSnakecaseKeys } from 'utils/object-cleanup';

const GeneralInfoForm = () => {
  const [isDobDateOpen, setIsDobDateOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [updateUser] = useUpdateUserMutation();
  const { organisationId, userId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useCustomNavigate();

  const { data: userData, isSuccess: isUserDataLoaded } = useGetUserQuery({ id: userId });
  const { data: roleOptions = [] } = useGetUserRolesQuery();
  const { data: currentUser } = useGetCurrentUserQuery();
  const { data: availablelanguageOptions = [] } = useGetSpokenLanguageListQuery();

  const isAdmin = isBioSuisseAdmin(currentUser);
  const isRedirectFromDashboard = searchParams.get('source');

  const handleCancelButton = () => {
    if (isRedirectFromDashboard) {
      return navigate.openRootPage();
    }
    if (!organisationId && isAdmin) {
      return navigate.openUsersPage();
    }
    if (organisationId) {
      return navigate.openOrganisationUserPage(organisationId);
    }
  };

  const salutationsOptions = [
    {
      label: t('user_salutation.mister'),
      value: 'mr',
    },
    {
      label: t('user_salutation.miss'),
      value: 'ms',
    },
    {
      label: t('user_salutation.neutral'),
      value: 'neutral',
    },
  ];

  const departmentOptions = [
    {
      label: 'Bio Cuisine',
      value: 'bio_cuisine',
    },
  ];

  const languageOptions = availablelanguageOptions.filter(availablelanguageOption => {
    return availablelanguageOption.value.length === 2;
  });

  const onSubmit = (values, form) => {
    if (values['spoken_languages']) {
      values['spoken_languages'] = [values['spoken_languages']];
    }

    return updateUser({ userId, payload: values })
      .unwrap()
      .then(() => form.reset())
      .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
  };

  const updateAvatar = image => {
    const values = {
      profile_picture: image,
      user_id: userId,
    };

    var formData = new FormData();

    Object.keys(values).map(key => formData.append(key, values[key]));

    updateUser({ userId: userId, payload: formData });
  };

  return (
    <>
      <div className="user__form--title">{t('user_edit.general_tab')}</div>
      <Card className="user__general-form--card">
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, form, submitting }) => (
            <form onSubmit={handleSubmit}>
              <div className="user__general-image__wrapper">
                <Avatar
                  src={userData?.profilePictureUrl}
                  firstName={userData?.firstName}
                  lastName={userData?.lastName}
                  size="huge"
                  showImageModal={false}
                />
                <AvatarModal updateAvatar={updateAvatar} userId={userId} />
              </div>
              <div className="col-grid col-12 col-bleed direction-row user__general-form--content">
                <div className="user__general-form--content-left">
                  <Field name="role" initialValue={isUserDataLoaded && userData.role}>
                    {({ input, meta }) => (
                      <SelectBox
                        size="tiny"
                        width="large"
                        label={t('user_general.type')}
                        placeholderText={t('user_general.placeholder')}
                        isClearable={false}
                        options={roleOptions}
                        value={roleOptions.find(option => option.value === input.value)}
                        selectedValue={input.label}
                        onChange={e => input.onChange(e.value)}
                        touched={!meta.dirtySinceLastSubmit}
                        errorMsg={meta?.submitError}
                        isDisabled={true}
                      />
                    )}
                  </Field>
                  <Field
                    name="first_name"
                    initialValue={isUserDataLoaded ? userData.firstName : ''}
                  >
                    {({ input }) => (
                      <Input size="tiny" label={t('user_general.first_name')} {...input} />
                    )}
                  </Field>
                  <Field
                    name="department"
                    type="select"
                    initialValue={isUserDataLoaded && userData.department}
                  >
                    {({ input, meta }) => (
                      <SelectBox
                        size="tiny"
                        width="large"
                        label={t('user_general.department')}
                        isClearable={false}
                        options={departmentOptions}
                        value={departmentOptions.find(option => option.value === input.value)}
                        selectedValue={input.label}
                        onChange={e => input.onChange(e.value)}
                        touched={!meta.dirtySinceLastSubmit}
                        errorMsg={meta?.submitError}
                      />
                    )}
                  </Field>
                  <Field
                    name="language"
                    type="select"
                    initialValue={isUserDataLoaded && userData.language}
                  >
                    {({ input }) => (
                      <SelectBox
                        size="tiny"
                        width="large"
                        label={t('user_general.prefered_language')}
                        placeholder={t('user_general.placeholder')}
                        isClearable={false}
                        options={languageOptions}
                        value={languageOptions.find(option => option.value === input.value)}
                        selectedValue={input.label}
                        onChange={e => input.onChange(e.value)}
                      />
                    )}
                  </Field>
                </div>
                <div className="user__general-form--content-right">
                  <Field name="salutation" initialValue={isUserDataLoaded && userData.salutation}>
                    {({ input }) => (
                      <SelectBox
                        size="tiny"
                        width="large"
                        label={t('user_general.salutation')}
                        placeholderText={t('user_general.placeholder')}
                        isClearable={false}
                        options={salutationsOptions && salutationsOptions}
                        value={
                          salutationsOptions &&
                          salutationsOptions.find(option => option.value === input.value)
                        }
                        selectedValue={input.label}
                        onChange={e => input.onChange(e.value)}
                      />
                    )}
                  </Field>
                  <Field name="last_name" initialValue={isUserDataLoaded ? userData.lastName : ''}>
                    {({ input }) => (
                      <Input size="tiny" label={t('user_general.last_name')} {...input} />
                    )}
                  </Field>
                  <Field name="birthday" initialValue={isUserDataLoaded && userData.birthday}>
                    {({ input, meta }) => (
                      <DatePicker
                        size="tiny"
                        focused={isDobDateOpen}
                        date={input.value}
                        onChange={e => input.onChange(e)}
                        onFocusChange={() => setIsDobDateOpen(prevState => !prevState)}
                        displayFormat="LL"
                        label={t('user_general.birthday')}
                        placeholder={t('user_general.dob_placeholder')}
                        navNext="arrowForwardAlt"
                        navPrev="arrowBackAlt"
                        enablePastDates={true}
                        disableFutureDates={true}
                        touched={!meta.dirtySinceLastSubmit}
                        error={meta?.submitError}
                        locale={i18n.language}
                      />
                    )}
                  </Field>
                  <Field
                    name="spoken_languages"
                    initialValue={isUserDataLoaded && userData.spokenLanguages[0]}
                  >
                    {({ input }) => (
                      <SelectBox
                        size="tiny"
                        width="large"
                        label={t('user_general.spoken_language')}
                        placeholderText={t('user_general.placeholder')}
                        isClearable={false}
                        options={languageOptions}
                        value={languageOptions.find(option => option.value === input.value)}
                        selectedValue={input.label}
                        onChange={e => input.onChange(e.value)}
                      />
                    )}
                  </Field>
                </div>
              </div>
              <div className="col-12 col-bleed user__general-form--footer">
                <Button
                  label={t('user_general.save_button')}
                  type="success"
                  size="small"
                  disabled={submitting}
                  submitType="submit"
                />
                <Button
                  className="user__general-form--footer__cancel-btn"
                  size="small"
                  label={t('user_general.cancel_button')}
                  onClick={handleCancelButton}
                />
              </div>
            </form>
          )}
        />
      </Card>
    </>
  );
};

export default GeneralInfoForm;
