import { useGetActiveGoodsDeclarationEvaluationQuery } from 'api/control-body-survey';
import {
  useGetOfflineSesstionsQuery,
  useVerifyOfflineSessionMutation,
} from 'api/control-body-survey/offline-sessions';
import { useGetCurrentUserQuery } from 'api/users';
import Card from 'components/base-components/Card';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader/ContentLoader';
import YesNoInput from 'components/base-components/YesNoInput';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { toSnakecaseKeys } from 'utils/object-cleanup';
import { CONTROL_BODY_USER, USER } from 'utils/users-role';
import { boolToYesNo } from 'utils/yes-no-helper';
import { t } from 'i18next';
import NegativeReportsHeader from '../header.component';
import AdditionalTaskSubmitButton from '../helpers/additional-task-submit-button.component';
import RandomGoodsDeclarationInfo from '../helpers/random-goods-declaration-info.component';
import NegativeTaskModal from '../helpers/negative-task-modal.component';
import MapGoods from './map-goods.component';

const ValuesOfGoods = ({ surveyId }) => {
  const { data: currentUser = {} } = useGetCurrentUserQuery();
  const { data: offlineSessions = [], isFetching } = useGetOfflineSesstionsQuery({
    surveyId,
    session_for: 'goods_declaration_evaluation',
  });

  const activeSession = offlineSessions.find(session => session.confirmedById !== null);
  const offlineSession = activeSession || offlineSessions[0];

  const { data: evaluation } = useGetActiveGoodsDeclarationEvaluationQuery(
    { surveyId },
    { skip: !offlineSession },
  );

  const [verify, approveStatus] = useVerifyOfflineSessionMutation();

  if (isFetching)
    return (
      <div className="negative-reports__container">
        <ContentLoaderPlaceholder numberOfLines={6} isRounded={true} />
      </div>
    );

  if (!offlineSession) return null;

  const onSave = values => {
    let payload = {};
    if (values.satisfied === 'yes') payload['satisfied'] = true;
    else if (values.satisfied === 'no') payload['satisfied'] = false;

    return verify({ sessionId: offlineSession.id, payload })
      .unwrap()
      .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
  };

  return (
    <div className="negative-reports__container">
      <NegativeTaskModal approveStatus={approveStatus} />
      <Card>
        <NegativeReportsHeader
          status={offlineSession.satisfied === null ? 'draft' : 'approved'}
          text={t('negative_report.value_of_goods')}
          date={offlineSession.createdAt}
          dueDate={offlineSession.dueDate}
        />

        <Form
          onSubmit={onSave}
          render={({ handleSubmit, submitting, submitErrors }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid">
                <div className="negative-reports__left-panel">
                  <div className="negative-reports__item-header">
                    {t('control_body.survey.form.second_step')}
                  </div>
                  <div>
                    {evaluation?.randomGoodsDeclarations.length === 0 ? (
                      <span>{t('common.no_data_available')}</span>
                    ) : (
                      evaluation?.randomGoodsDeclarations.map(randomGoodsDeclaration => (
                        <RandomGoodsDeclarationInfo randomGoodsDeclaration={randomGoodsDeclaration}>
                          <MapGoods />
                        </RandomGoodsDeclarationInfo>
                      ))
                    )}
                  </div>
                </div>
                <div className="negative-reports__right-panel">
                  <div className="negative-reports__form-description">
                    {currentUser.role === USER ? (
                      <ul className="negative-reports__item-list">
                        <li>{t('negative_report.list_1')}</li>
                        <li>{t('negative_report.list_2')}</li>
                      </ul>
                    ) : (
                      <Field name="satisfied" initialValue={boolToYesNo(offlineSession.satisfied)}>
                        {({ input, meta }) => (
                          <YesNoInput
                            {...input}
                            error={meta.submitError}
                            disabled={
                              offlineSession.satisfied !== null ||
                              currentUser.role !== CONTROL_BODY_USER
                            }
                            label={t('negative_report.satisfied_label')}
                          />
                        )}
                      </Field>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  {currentUser.role === CONTROL_BODY_USER && (
                    <AdditionalTaskSubmitButton
                      status={offlineSession.satisfied === null ? 'submitted' : 'approved'}
                      disabled={submitting}
                      approverType={CONTROL_BODY_USER}
                    />
                  )}
                </div>
              </div>
            </form>
          )}
        />
      </Card>
    </div>
  );
};

ValuesOfGoods.propTypes = {
  surveyId: PropTypes.number.isRequired,
};

export default ValuesOfGoods;
