import React from 'react';
import { useCopySupplierCertificatesMutation } from 'api/supplier-certificates';
import { useGetCurrentUserQuery } from 'api/users';
import Button from 'components/base-components/Button';
import Icon from 'components/base-components/Icon';
import Modal from 'components/base-components/Modal';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { USER } from 'utils/users-role';
import DeclarationData from './declaration-data';
import './styles.scss';

const CopyDeclarationComponent = ({ disabled }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState({});
  const { id: organizationId } = useParams();
  const { data: currentUser = {} } = useGetCurrentUserQuery();
  const [copy, { isLoading, isError, error }] = useCopySupplierCertificatesMutation();

  if (currentUser.role !== USER) return null;

  const onSubmit = () => {
    const checkedIds = Object.keys(selectedIds).filter(key => selectedIds[key]);

    return copy({ organizationId, payload: { ids: checkedIds } })
      .unwrap()
      .then(() => setModalOpen(false));
  };

  return (
    <>
      <Button
        className="copy-declarations__action-button"
        label={t('supplier_certificates.copy.copy_from_button')}
        size="small"
        onClick={() => setModalOpen(true)}
        disabled={isLoading || disabled}
        type="default"
      />

      <Modal isVisible={modalOpen} onOutsideClick={() => setModalOpen(false)}>
        <Modal.Title className="copy-modal__header">
          <div>{t('supplier_certificates.copy.copy_title')}</div>
          <div onClick={() => setModalOpen(false)}>
            <Icon size="small" color="tertiary" name="close" />
          </div>
        </Modal.Title>
        <Modal.Content>
          <DeclarationData
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            organizationId={organizationId}
          />
        </Modal.Content>
        <Modal.Footer className="copy-modal__footer">
          <div className="flex-center ">
            {isError && <div className="copy-modal__error-text">{error.data.message} </div>}
            <Button
              label={t('supplier_certificates.copy.copy_selected')}
              size="small"
              type="success"
              onClick={onSubmit}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

CopyDeclarationComponent.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default CopyDeclarationComponent;
