import { Text, View } from '@react-pdf/renderer';
import styles from 'components/pdf/styles/checklist-styles';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

const AuditorRemarks = ({ remark }) => (
  <View>
    <Text style={styles.checklistMarginForty}>
      <Text style={styles.heading2}>{t('control_body.survey.form.auditor_remarks')}</Text>
    </Text>
    <Text style={styles.checklistMarginFive}>
      <Text>{remark}</Text>
    </Text>
  </View>
);

AuditorRemarks.propTypes = {
  remark: PropTypes.string.isRequired,
};

export default AuditorRemarks;
