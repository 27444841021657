import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/base-components/IconButton';
import Icon from 'components/base-components/Icon';
import classNames from 'classnames';
import './image-modal.scss';

export default function ImageModal({ children, showImageModal }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {React.cloneElement(children, {
        onClick: () => setIsOpen(true),
        tabIndex: 0,
        className: classNames(children.props.className, 'image-modal__wrapper'),
      })}
      <div
        className={classNames('image-modal__overlay', {
          'image-modal__overlay--hidden': !isOpen || !showImageModal,
        })}
        onClick={() => setIsOpen(false)}
      >
        <div className="image-modal__content" onClick={e => e.stopPropagation()}>
          {children}
          <IconButton
            icon={<Icon name="close" size="tiny" />}
            color="white"
            size="large"
            onClick={() => setIsOpen(false)}
          />
        </div>
      </div>
    </>
  );
}

ImageModal.defaultProps = {
  children: null,
  showImageModal: true,
};

ImageModal.propTypes = {
  children: PropTypes.node,
  showImageModal: PropTypes.bool,
};
