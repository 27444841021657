import { t } from 'i18next';
import { useMemo } from 'react';
import ADDRESS_TYPE from 'utils/address-type';
import { MOBILE, PHONE } from 'utils/contact_type';
import ORGANISATION_TYPE from 'utils/organisation-type';

export const InitialValuesdata = (organization, isLoading, phoneNumberByType) => {
  const initialValues = useMemo(() => {
    if (!organization || isLoading) {
      return {};
    }
    let addresses = {};
    organization.addresses.forEach(address => {
      const attributes = {
        id: address.id,
        addressName: address.addressName,
        zipCode: address.zipCode,
        line1: address.line1,
        line2: address.line2,
        country: { label: address?.country?.name, value: address?.country?.code },
        city: address.city,
        canton: address?.canton,
      };

      if (address.main) {
        addresses = { ...addresses, mainAddress: attributes };
      } else if (address.type === ADDRESS_TYPE.invoice) {
        addresses = { ...addresses, billingAddress: attributes };
      } else if (address.type === ADDRESS_TYPE.mailing) {
        addresses = { ...addresses, mailingAddress: attributes };
      }
    });

    return {
      uid: organization.uid,
      additionalName: organization.additionalName,
      registeredName: organization.name,
      industry: {
        label: t(`organisation.industry.${organization.industry}`),
        value: organization.industry,
      },
      preferredContact: organization.phoneNumbers.find(phoneNumber => phoneNumber.primary)
        ?.numberType,
      phone: phoneNumberByType[PHONE]?.at(0)?.number,
      cellphone: phoneNumberByType[MOBILE]?.at(0)?.number,
      emailId: organization?.emailAddresses?.[0]?.id,
      email: organization?.emailAddresses?.[0]?.email,
      billingAddress: addresses.billingAddress,
      mainAddress: addresses.mainAddress,
      mailingAddress: addresses.mailingAddress,
      enableMailingAddress: !!addresses.mailingAddress,
      enableParentOrganisation: organization.parentId,
      receiveInvoice: organization.isInvoiceRecipient,
      organisationType: organization.type || ORGANISATION_TYPE.single_organisation,
      draft: false,
    };
  }, [JSON.stringify(organization)]);

  return initialValues;
};
