import React, { Fragment, useState } from 'react';
import {
  useGetLastControlBodySurveyQuery,
  useRequestCertificateMutation,
  useUploadCertificateMutation,
  useDeleteCertificateMutation,
  useGetControlBodyScheduleQuery,
} from 'api/control-body-survey';
import {
  PERMANENT,
  POSITIVE,
  SAVED,
  TEMPORARY,
  hasPermissionToFinshSurvey,
} from 'utils/control-body-survey';
import { t } from 'i18next';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import isControlBodyMember from 'utils/is-control-body-member';
import { useGetCurrentUserQuery } from 'api/users';
import Button from 'components/base-components/Button';
import Card from 'components/base-components/Card';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import ChooseFile from 'components/shared-components/choose-file/ChooseFile';
import { SuccessModal } from 'components/shared-components/modal/submit-modal';
import { useParams } from 'react-router';
import { USER } from 'utils/users-role';
import { dateMonthYear } from 'utils/date-time-formatter';
import classNames from 'classnames';
import { Table, TableBody, TableData, TableRow } from 'components/base-components/Table';
import { DRAFT, FINAL } from 'utils/verification-status';
import { useGetActiveControlBodyDeclarationQuery } from 'api/control-body-declarations';
import ReportPDF from '../report-pdf/index.component';
import UpcomingControlVerificationTag from './control-verification-tag';
import LastControlFlowNavigation from './last-control-flow-navigation';

const LastControlFlow = () => {
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();

  const { data: lastControlBodySurveyData } = useGetLastControlBodySurveyQuery({
    organizationId: id,
  });

  const { data: controlBodyScheduleData = {} } = useGetControlBodyScheduleQuery(
    { surveyId: lastControlBodySurveyData?.id },
    { skip: !lastControlBodySurveyData?.id },
  );
  const { data: controlBodyDeclaration = {} } = useGetActiveControlBodyDeclarationQuery(id);

  const [uploadCertificate] = useUploadCertificateMutation();
  const [requestCertificate] = useRequestCertificateMutation();
  const [deleteCertificate] = useDeleteCertificateMutation();

  const { data: currentUser = {} } = useGetCurrentUserQuery();

  const admin = isBioSuisseAdmin(currentUser);

  const isOrganisationMember = !admin && currentUser.role === USER;

  if (!lastControlBodySurveyData) {
    return null;
  }

  const onRequestCertificate = () =>
    requestCertificate({ surveyId: lastControlBodySurveyData.id })
      .unwrap()
      .then(() => {
        setShowModal(true);
      });

  const onUploadCertificate = certificate => {
    let formData = new FormData();
    formData.append('certificate', certificate);
    uploadCertificate({ surveyId: lastControlBodySurveyData.id, payload: formData })
      .unwrap()
      .then(() => {
        setShowModal(true);
      });
  };

  const downloadCertificate = () => {
    const newWindow = window.open(lastControlBodySurveyData?.certificate?.url, '_blank');
    newWindow.focus();
  };

  const onDeleteCertificate = () =>
    deleteCertificate({ surveyId: lastControlBodySurveyData.id }).unwrap();

  const daysLeftToConfirmReceipt = parseInt(
    (lastControlBodySurveyData?.hourLeftToConfirmReceipt || 0) / 24,
    10,
  );
  const isAuditor = !hasPermissionToFinshSurvey(
    currentUser.assignments,
    controlBodyDeclaration.controlBodyOrganizationId,
  );

  const enableCertificateUpload =
    lastControlBodySurveyData?.assessmentOutcome === POSITIVE && !isAuditor;

  const certificateUploaded = lastControlBodySurveyData?.certificate?.id;
  const receiptConfirmed = lastControlBodySurveyData.receiptConfirmed;
  const modalKey = isControlBodyMember(currentUser) ? 'upload' : 'receipt';

  const pdfReportTitle = () => {
    switch (lastControlBodySurveyData.status) {
      case PERMANENT:
        return t('control_body.survey.form.final_report');
      case TEMPORARY:
        return t('control_body.survey.form.provisional_report');
      default:
        return t('control_body.survey.form.draft_report');
    }
  };

  const reportDate =
    lastControlBodySurveyData.finalReportCreatedAt ||
    lastControlBodySurveyData.draftReportCreatedAt;

  const formattedReportDate = reportDate ? dateMonthYear(reportDate) : t('common.not_applicable');

  return (
    <Fragment>
      <SuccessModal
        title={t(`control_body.survey.modal.${modalKey}.title`)}
        content={t(`control_body.survey.modal.${modalKey}.content`)}
        footer={
          <Button
            label={t('shared.action.okay')}
            size="small"
            onClick={() => setShowModal(false)}
            type="success"
          />
        }
        isVisible={showModal}
      />
      <Card
        className={classNames('control-flow__overview-card', {
          'control-flow__overview-card--admin-user':
            (isControlBodyMember(currentUser) && lastControlBodySurveyData?.status === PERMANENT) ||
            (!isControlBodyMember(currentUser) &&
              lastControlBodySurveyData?.status &&
              lastControlBodySurveyData?.assessmentOutcome !== POSITIVE),
        })}
      >
        <div className="control-flow__upcoming-control">
          {t('control_flow.last_control.header')}
        </div>
        <div className="control-flow__wrapper">
          <div className="control-flow__wrapper-control">
            <div className="control-flow__wrapper-status">
              <div className="control-flow__wrapper-status--label">
                {t('control_flow.last_control.date')}
              </div>
              <div className="control-flow__wrapper-status--value">
                {dateMonthYear(controlBodyScheduleData?.startsAt) || t('common.not_applicable')}
              </div>
            </div>
            <div className="control-flow__wrapper-date">
              <div className="control-flow__wrapper-date--label">{t('control_flow.status')}</div>
              <div className="control-flow__wrapper-date--value">
                <span className="control-flow__wrapper-date--value__complete">
                  {lastControlBodySurveyData?.status === DRAFT ||
                  lastControlBodySurveyData?.status === SAVED
                    ? t('control_flow.incomplete')
                    : t('control_flow.complete')}
                </span>
              </div>
            </div>
            <div className="control-flow__wrapper-remaining">
              <div className="control-flow__wrapper-remaining--label">
                {t('control_flow.last_control.result')}
              </div>
              <div className="control-flow__wrapper-remaining--value">
                <div className="control-flow__wrapper-remaining--value__tag">
                  {lastControlBodySurveyData && (
                    <UpcomingControlVerificationTag
                      status={lastControlBodySurveyData?.status}
                      assessmentOutcome={lastControlBodySurveyData.assessmentOutcome}
                    />
                  )}
                </div>
              </div>
            </div>
            {isControlBodyMember(currentUser) &&
              lastControlBodySurveyData?.status !== PERMANENT && (
                <LastControlFlowNavigation survey={lastControlBodySurveyData} />
              )}
            {isOrganisationMember && lastControlBodySurveyData.assessmentOutcome === POSITIVE && (
              <div className="control-flow__wrapper-control-confirm-receipt">
                <Button
                  label={t('control_body.survey.confirm_receipt')}
                  size="small"
                  onClick={onRequestCertificate}
                  type="success"
                  disabled={receiptConfirmed}
                />
                {!receiptConfirmed && (
                  <span className="control-flow__wrapper-control--remaining-days">
                    {t('control_body.survey.days_left', { count: daysLeftToConfirmReceipt })}
                  </span>
                )}
              </div>
            )}
          </div>
          <div className="control-flow__seperator control-flow__seperator-latest" />
          <div className="control-flow__wrapper-report control-flow__wrapper-report--last">
            <div className="control-flow__related-files--header">
              {t('control_flow.last_control.related_files')}
            </div>
            <Table className="control-flow__related-files--table">
              <TableBody>
                <TableRow>
                  <TableData>{pdfReportTitle()}</TableData>
                  <TableData>{formattedReportDate}</TableData>
                  <ReportPDF
                    survey={lastControlBodySurveyData}
                    reportType={lastControlBodySurveyData.status === PERMANENT ? FINAL : DRAFT}
                  />
                </TableRow>
                <TableRow>
                  <TableData>{t(`control_body.survey.certificate`)}</TableData>
                  <TableData>
                    {(certificateUploaded &&
                      dateMonthYear(lastControlBodySurveyData?.certificate?.uploadedAt)) ||
                      t('common.not_applicable')}
                  </TableData>
                  <TableData>
                    {lastControlBodySurveyData?.certificate?.filename || t('common.not_applicable')}
                  </TableData>
                  <TableData>
                    <div className="control-flow__icon">
                      <div className="control-flow__icon-couple">
                        {certificateUploaded && isControlBodyMember(currentUser) && (
                          <IconButton
                            icon={<Icon name="delete" size="normal" color="danger" />}
                            color="danger"
                            size="normal"
                            disabled={!enableCertificateUpload}
                            onClick={onDeleteCertificate}
                          />
                        )}
                        {!certificateUploaded && isControlBodyMember(currentUser) && (
                          <ChooseFile
                            withIconButton={true}
                            isIconOnly={false}
                            onChange={onUploadCertificate}
                            disableIconButton={!enableCertificateUpload}
                            size="normal"
                          />
                        )}
                        {(certificateUploaded || !isControlBodyMember(currentUser)) && (
                          <IconButton
                            className="organization-education__table-row--download-icon"
                            icon={<Icon name="downloadFile" size="normal" />}
                            color="default"
                            onClick={downloadCertificate}
                            disabled={!certificateUploaded}
                          />
                        )}
                      </div>
                    </div>
                  </TableData>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
      </Card>
    </Fragment>
  );
};

export default LastControlFlow;
