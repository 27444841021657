import ADDRESS_TYPE from 'utils/address-type';
import { MOBILE, PHONE } from 'utils/contact_type';

export const addressValues = (values, formData, phoneNumberByType) => {
  const addressesAttributes = ({ address, type, formData, main = false, destroyed = false }) => {
    if (!address) {
      return {};
    }

    let attributes = {
      address_type: type,
      id: address.id,
      address_name: address.addressName || null,
      city: address.city || null,
      zip_code: address.zipCode || null,
      line1: address.line1 || null,
      line2: address.line2 || null,
      main: main,
      country_code: address?.country?.value,
      canton: address?.canton,
    };

    if (destroyed) {
      attributes = { ...attributes, destroy: true };
    }

    prepareNestedAttributes({ entityName: 'addresses', attributes, formData });
  };

  const prepareNestedAttributes = ({ entityName, attributes, formData }) => {
    Object.keys(attributes).forEach(key => {
      if (attributes[key]) {
        formData.append(`${entityName}[][${key}]`, attributes[key]);
      }
    });
  };

  const emailAttributes = {
    id: values.emailId,
    email: values.email,
    email_type: values.email ? 'other' : null,
  };

  if (values.emailId) emailAttributes['destroy'] = !values.email;

  prepareNestedAttributes({ entityName: 'email_addresses', attributes: emailAttributes, formData });

  const preparePhoneNumbers = ({ values, formData }) => {
    [PHONE, MOBILE].forEach(numberType => {
      if (phoneNumberByType[numberType] || values[numberType]) {
        const attributes = {
          id: phoneNumberByType[numberType]?.at(0)?.id,
          number: values[numberType],
          number_type: numberType,
          primary: values.preferredContact === numberType,
          destroy: !values[numberType],
        };

        prepareNestedAttributes({ entityName: 'phone_numbers', attributes, formData });
      }
    });
  };

  preparePhoneNumbers({ values, formData });

  if (values.billingAddress || values.receiveInvoice) {
    addressesAttributes({
      address: values.billingAddress || { address_name: '' },
      type: ADDRESS_TYPE.invoice,
      formData,
      destroyed: !values.receiveInvoice,
    });
  }

  if (values.enableMailingAddress) {
    addressesAttributes({
      address: values.mailingAddress || { address_name: '' },
      type: ADDRESS_TYPE.mailing,
      formData,
      destroyed: !values.enableMailingAddress,
    });
  }

  addressesAttributes({
    address: values.mainAddress || { address_type: ADDRESS_TYPE.general },
    type: ADDRESS_TYPE.general,
    formData,
    main: true,
  });
};
