import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton/IconButton';
import TableLoaderPlaceholder from 'components/shared-components/placeholders/table-loader-placeholder';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import {
  Table,
  TableHeader,
  TableHead,
  TableRow,
  TableData,
  TableBody,
} from 'components/base-components/Table';
import { dateMonthYearFormatter } from 'utils/date-time-formatter.js';
import { dateMonthYear } from 'utils/date-time-formatter';
import { useGetOrganizationQuery } from 'api/organizations';
import OrganizationName from 'components/organisations/organization-name';
import Spinner from 'components/base-components/Spinner/Spinner';
import EmphasisTag from 'components/base-components/EmphasisTag';
import Popover from 'components/base-components/Popover/index.js';
import CheckBox from 'components/base-components/CheckBox/CheckBox';
import { SENT } from 'utils/recognition-status.js';
import { useGetUserQuery } from 'api/users/index.js';
import { Field, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import RecognitionPDFComponent from './recognition-pdf.component.jsx';

const RecognitionDetailsTableRow = ({
  certificate,
  currentTab,
  currentPage,
  index,
  handleDeleteCertificate,
}) => {
  const { t } = useTranslation();
  const { data: organization } = useGetOrganizationQuery(certificate?.organizationId);
  const [showPopover, setShowPopover] = useState(false);
  const { values } = useFormState();
  const { data: user = {} } = useGetUserQuery(
    { id: organization?.mainContactId },
    { skip: !organization?.mainContactId },
  );

  return (
    <>
      <TableRow className="organisation__table-row">
        <TableData>
          <Field
            name={`certificates[${currentPage - 1}][${index}].checked`}
            type="checkbox"
            initialValue={
              values[`selectAllInCurrentPage__${currentPage}`] ||
              values?.selectAllCertificate ||
              false
            }
          >
            {({ input, meta }) => (
              <CheckBox
                {...input}
                label={
                  organization
                    ? `Recognition ${organization.bioId} ${organization.certificationStatus}`
                    : t('common.not_applicable')
                }
                isChecked={input.checked}
                size="tiny"
                onChange={e => input.onChange(e.target.checked)}
              />
            )}
          </Field>
          <Field
            name={`certificates[${currentPage - 1}][${index}].id`}
            type="hidden"
            component="input"
            initialValue={certificate?.id}
          />
        </TableData>
        <TableData>
          {organization ? (
            <OrganizationName fetchedOrganisation={organization} />
          ) : (
            <Spinner bgColor="none" color="success" size="tiny" />
          )}
        </TableData>
        <TableData>
          {organization ? (
            <EmphasisTag type="normal" size="tiny" text={organization?.industry} />
          ) : (
            t('common.not_applicable')
          )}
        </TableData>
        <TableData>{dateMonthYear(certificate.createdAt)}</TableData>
        <TableData align="right">
          <Popover
            className="task__popover-wrapper"
            direction="bottom-right"
            isVisible={showPopover}
            onOutsideClick={() => setShowPopover(false)}
            content={
              <div className="task__popover">
                {organization && (
                  <RecognitionPDFComponent
                    organization={organization}
                    user={user}
                    certificateGenDate={dateMonthYearFormatter(certificate.createdAt)}
                  />
                )}
                {currentTab === SENT && (
                  <div
                    className="task__popover-content"
                    onClick={() => handleDeleteCertificate(certificate.id)}
                  >
                    {t('recognition.details.table.popovers.delete_certificate')}
                  </div>
                )}
              </div>
            }
          >
            <IconButton
              className="organisation__action-show"
              icon={<Icon name="horizontalDots" size="small" />}
              onClick={() => setShowPopover(true)}
            />
          </Popover>
        </TableData>
      </TableRow>
    </>
  );
};

RecognitionDetailsTableRow.defaultProps = {
  certificate: null,
  index: null,
  currentTab: 'unsent',
  currentPage: 1,
  handleDeleteCertificate: () => {},
};

RecognitionDetailsTableRow.propTypes = {
  certificate: PropTypes.shape(),
  currentTab: PropTypes.string,
  currentPage: PropTypes.number,
  index: PropTypes.node,
  handleDeleteCertificate: PropTypes.func,
};

const RecognitionDetailsTable = ({
  certificateList,
  isFetching,
  currentTab,
  currentPage,
  handleDeleteCertificate,
  form,
}) => {
  const { t } = useTranslation();

  const handleSelectAllInCurrentPage = (isSelectAll, input) => {
    input.onChange(isSelectAll);

    const currentCertificates = form.getState().values.certificates[currentPage - 1];
    const updatedCertificates = currentCertificates.map(certificate => ({
      ...certificate,
      checked: isSelectAll,
    }));

    form.change(`certificates[${currentPage - 1}]`, updatedCertificates);
  };

  const RecignitionTableHeader = () => (
    <TableHead>
      <TableRow>
        <TableHeader>
          <Field name={`selectAllInCurrentPage__${currentPage}`} type="checkbox">
            {({ input }) => (
              <CheckBox
                {...input}
                label={t('recognition.details.table.certificate_title')}
                isChecked={input.checked}
                size="tiny"
                onChange={e => handleSelectAllInCurrentPage(e.target.checked, input)}
              />
            )}
          </Field>
        </TableHeader>
        <TableHeader>{t('recognition.details.table.organization')}</TableHeader>
        <TableHeader>{t('recognition.details.table.department')}</TableHeader>
        <TableHeader>{t('recognition.details.table.generate_date')}</TableHeader>
        <TableHeader align="right">{t('recognition.details.table.actions')}</TableHeader>
      </TableRow>
    </TableHead>
  );
  return (
    <>
      {!isFetching && certificateList.length === 0 ? (
        <EmptyContentPlaceholder iconName="certificate" text={t('recognition.no_recognition')} />
      ) : (
        <Table className="organisation_table recognition__table">
          <RecignitionTableHeader />
          <TableBody>
            {isFetching ? (
              <TableLoaderPlaceholder numberOfRows={10} />
            ) : (
              <FieldArray name="certificates">
                {() =>
                  certificateList.map((certificate, index) => (
                    <RecognitionDetailsTableRow
                      certificate={certificate}
                      currentTab={currentTab}
                      currentPage={currentPage}
                      index={index}
                      handleDeleteCertificate={handleDeleteCertificate}
                    />
                  ))
                }
              </FieldArray>
            )}
          </TableBody>
        </Table>
      )}
    </>
  );
};

RecognitionDetailsTable.defaultProps = {
  certificateList: [],
  isFetching: false,
  currentTab: 'unsent',
  currentPage: 1,
  handleDeleteCertificate: () => {},
};

RecognitionDetailsTable.propTypes = {
  certificateList: PropTypes.arrayOf(PropTypes.shape()),
  isFetching: PropTypes.bool,
  currentTab: PropTypes.string,
  currentPage: PropTypes.number,
  handleDeleteCertificate: PropTypes.func,
  form: PropTypes.node.isRequired,
};

export default RecognitionDetailsTable;
