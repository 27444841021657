import React from 'react';
import Button from 'components/base-components/Button';
import { useTranslation } from 'react-i18next';
import withNavigation from 'utils/withNavigation';
import './error_page.scss';

const ShowErrorPage = () => {
  const { t } = useTranslation();

  return (
    <div className="error_page--error-wrapper">
      <div className="error_page--content">
        <span className="error_page--title">{t('fallback_pages.error_page.title')}</span>
        <span>{t('fallback_pages.error_page.description1')}</span>
        <span>{t('fallback_pages.error_page.description2')}</span>
        <Button
          className="error_page--button"
          label={t('fallback_pages.home_button')}
          type="success"
          size="small"
          onClick={() => (window.location.href = '/')}
        />
      </div>
    </div>
  );
};

export default withNavigation(ShowErrorPage);
