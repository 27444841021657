import React from 'react';
import useCustomNavigate from 'hooks/useCustomNavigate';
import WelcomePageComponent from 'components/welcomepage/welcome-page.component';
import TwoFactorConfiguration from 'components/users/credential-management/two-factor/form.component';
import { useEnableTwoFactorAuthenticationMutation } from 'api/two-factor-authentication';

export default function Enforce2FA() {
  const navigate = useCustomNavigate();
  return (
    <WelcomePageComponent onlyChildren={true}>
      <TwoFactorConfiguration
        verifyAndEnableMutation={useEnableTwoFactorAuthenticationMutation}
        onSave={navigate.openLoginPage}
      />
    </WelcomePageComponent>
  );
}
