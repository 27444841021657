import {
  useDeclareSupplierCertificateDeclarationsMutation,
  useGetSupplierCertificateDeclarationsQuery,
} from 'api/supplier-certificates';
import Button from 'components/base-components/Button';
import WarningModal from 'components/shared-components/modal/warning';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import useCustomNavigate from 'hooks/useCustomNavigate';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SUPPLIER_CERTIFICATE_TYPE from 'utils/supplier-certificate-type';
import ComplainModal from '../shared/complain-modal.component';

const DeclareCertificate = ({
  draftCertificateDeclarations,
  organizationId,
  isComplained,
  isDisabled,
  complain,
}) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { t } = useTranslation();
  const [
    declareCertificateDeclarations,
    { isSuccess: isDeclarationSuccessful, requestId: declareRequestId },
  ] = useDeclareSupplierCertificateDeclarationsMutation();

  const onDeclareCertificateDeclarations = () => {
    return declareCertificateDeclarations({ organizationId });
  };

  const { data: certificateDeclaration } = useGetSupplierCertificateDeclarationsQuery({
    organizationId,
    'status[]': [SUPPLIER_CERTIFICATE_TYPE.approved],
  });

  const [approvedCertificateDeclaration] = certificateDeclaration?.declarations || [];

  const navigate = useCustomNavigate();

  return (
    <div>
      {!isComplained ? (
        <>
          <Button
            label={t('supplier_certificates.declare_certificates')}
            size="small"
            onClick={() => setIsConfirmModalOpen(true)}
            disabled={
              isDisabled ||
              approvedCertificateDeclaration ||
              (draftCertificateDeclarations && draftCertificateDeclarations.length === 0)
            }
            type="success"
          />
        </>
      ) : (
        <div className="supplier-certificates__header-redeclare">
          <div className="supplier-certificates__header-redeclare-warning">
            {t('supplier_certificates.redeclare.warning')}
          </div>
          {complain && <ComplainModal complain={complain} />}
          <Button
            label={t('supplier_certificates.redeclare.action')}
            size="small"
            onClick={() => setIsConfirmModalOpen(true)}
            disabled={draftCertificateDeclarations?.length === 0}
            type="success"
          />
        </div>
      )}
      <WarningModal
        isVisible={isConfirmModalOpen}
        onConfirm={() => {
          setIsConfirmModalOpen(false);
          onDeclareCertificateDeclarations();
        }}
        onCancel={() => setIsConfirmModalOpen(false)}
        title={t('supplier_certificates.modal.declaration_confimation.title')}
        content={t('supplier_certificates.modal.declaration_confimation.body')}
        confirmActionText={t('supplier_certificates.declare_certificates')}
        cancelActionText={t('shared.action.cancel')}
      />
      <SubmitModal
        isSuccess={isDeclarationSuccessful}
        requestId={declareRequestId}
        successTitle={t('supplier_certificates.modal.draft_declaration_successful.title')}
        successContent={t('supplier_certificates.modal.draft_declaration_successful.body')}
        showError={false}
        showLoader={false}
        onSuccess={() => navigate.openOrganisationDetailsPage(organizationId)}
      />
    </div>
  );
};

DeclareCertificate.defaultProps = {
  isComplained: false,
  isDisabled: false,
  complain: null,
};

DeclareCertificate.propTypes = {
  organizationId: PropTypes.number.isRequired,
  draftCertificateDeclarations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isComplained: PropTypes.bool,
  isDisabled: PropTypes.bool,
  complain: PropTypes.node,
};

export default DeclareCertificate;
