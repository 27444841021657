import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';

const MapGoods = ({ validityPeriod, totalAmount, customInput }) => {
  return (
    <div className="random-goods-declarations__wrapper">
      <ul className="random-goods-declarations__list">
        <li>
          <div className="random-goods-declarations__values">
            <span>
              {t('negative_report.random_goods.header')} {validityPeriod} :
            </span>
            <span>{`${totalAmount} CHF`}</span>
          </div>
        </li>
        <li>
          <div className="random-goods-declarations__values">
            <span>
              {t('negative_report.random_goods.vat')} {validityPeriod}
            </span>
            <span>{customInput} CHF</span>
          </div>
        </li>
      </ul>
    </div>
  );
};

MapGoods.propTypes = {
  validityPeriod: PropTypes.string.isRequired,
  totalAmount: PropTypes.number.isRequired,
  customInput: PropTypes.string.isRequired,
};

export default MapGoods;
