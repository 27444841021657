import { Text, View } from '@react-pdf/renderer';
import styles from 'components/pdf/styles/checklist-styles';
import { t } from 'i18next';
import React from 'react';
import { dateMonthYear } from 'utils/date-time-formatter';
import PropTypes from 'prop-types';

const Header = ({ organizationName, date, time, auditorName }) => {
  return (
    <>
      <View style={styles.checklistMarginTwentyBottom}>
        <Text style={styles.title}>
          {t('control_body.survey.form.alphabet_control', { name: organizationName })}
        </Text>
      </View>
      <View style={styles.checkListFlex}>
        <View style={styles.checkListFlex}>
          <Text style={styles.heading2}>{t('control_body.survey.form.auditor_name')}: </Text>
          <Text>{auditorName}</Text>
        </View>
        <View style={styles.checkListFlex}>
          <Text style={[styles.checklistMarginTwentyLeft, styles.heading2]}>
            {t('control_body.survey.form.control_date')}
          </Text>
          <Text> {date ? dateMonthYear(date) : t('common.not_applicable')}</Text>
        </View>
        <View style={styles.checkListFlex}>
          <Text
            style={{
              ...styles.checklistMarginTwentyLeft,
              ...styles.heading2,
            }}
          >
            {' '}
            {t('control_body.survey.form.time')}
          </Text>
          <Text> {time || t('common.not_applicable')}</Text>
        </View>
      </View>
    </>
  );
};

Header.propTypes = {
  organizationName: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  auditorName: PropTypes.string.isRequired,
};

export default Header;
