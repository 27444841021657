import React from 'react';
import { Field } from 'react-final-form';
import Input from 'components/base-components/Input';
import { useTranslation } from 'react-i18next';
import { PHONE, MOBILE } from 'utils/contact_type';
import PropTypes from 'prop-types';
import RadioWithoutCheck from 'components/base-components/RadioWithoutCheck';

const OrganisationContact = ({ preferredContact }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="organisation__form--font-bold">{t('shared.attributes.contact')}</div>
      <div className="organisation__form-contacts-placeholder">
        {t('organisation.form.contacts.description')}
      </div>
      <div className="organisation__form--margin-top">
        {t('organisation.form.contacts.preferred')}
      </div>
      <Field name="preferredContact">
        {({ input, meta }) => (
          <div className="organisation__form-preferred-contact">
            {[PHONE, MOBILE].map(contactType => (
              <RadioWithoutCheck
                key={contactType}
                isChecked={input.value === contactType}
                label={t(`shared.contacts.number_type.${contactType}`)}
                name={contactType}
                onChange={() => input.onChange(contactType)}
                touched={!meta.dirtySinceLastSubmit}
                error={meta.submitError}
                size="medium"
                data-testid={contactType}
              />
            ))}
          </div>
        )}
      </Field>
      <div className="organisation__form-contact organisation__form--margin-top">
        <Field name={PHONE}>
          {({ input, meta }) => (
            <Input
              size="tiny"
              label={t('shared.contacts.number_type.phone')}
              className="organisation__form-contact-field"
              placeholder={t('organisation.form.placeholder.phone_no')}
              touched={!meta.dirtySinceLastSubmit}
              error={meta.submitError}
              required={preferredContact === PHONE}
              {...input}
            />
          )}
        </Field>
        <Field name={MOBILE}>
          {({ input, meta }) => (
            <Input
              size="tiny"
              label={t('shared.contacts.number_type.cellphone')}
              className="organisation__form-contact-field"
              placeholder={t('organisation.form.placeholder.mobile_no')}
              error={meta.submitError}
              required={preferredContact === MOBILE}
              {...input}
            />
          )}
        </Field>
      </div>
      <Field name="emailId" type="hidden" component="input" />
      <div className="organisation__form-contact organisation__form--margin-top">
        <Field name="email">
          {({ input, meta }) => {
            return (
              <Input
                {...input}
                size="tiny"
                label={t('login.email_label')}
                className="organisation__form-contact-field"
                placeholder={t('login.email_placeholder')}
                required={false}
                touched={!meta.dirtySinceLastSubmit}
                error={meta.submitError}
              />
            );
          }}
        </Field>
      </div>
    </>
  );
};
OrganisationContact.defaultProps = {
  preferredContact: null,
};

OrganisationContact.propTypes = {
  preferredContact: PropTypes.string,
};

export default OrganisationContact;
