import React, { useEffect } from 'react';
import { useGetGoodsDeclarationQuery } from 'api/goods-declarations';
import DefaultSpinner from 'components/shared-components/default-spinner';
import PropTypes from 'prop-types';
import { monthYearShort } from 'utils/date-time-formatter';

const RandomGoodsDeclarationInfo = ({ randomGoodsDeclaration, setEvaluationPeriods, children }) => {
  const { data, isFetching } = useGetGoodsDeclarationQuery(
    randomGoodsDeclaration.goodsDeclarationId,
  );

  const validityPeriod = `${monthYearShort(data?.periodFrom)} - ${monthYearShort(
    data?.periodUntil,
  )}`;

  useEffect(() => {
    if (data) {
      setEvaluationPeriods(prev => [...prev, validityPeriod]);
    }
  }, [data?.id]);

  const totalAmount =
    parseInt(data?.month1TotalAmount, 10) +
    parseInt(data?.month2TotalAmount, 10) +
    parseInt(data?.month3TotalAmount, 10);

  if (isFetching) return <DefaultSpinner />;

  return React.cloneElement(children, {
    validityPeriod,
    totalAmount,
    customInput: randomGoodsDeclaration.customInput,
    data,
  });
};

RandomGoodsDeclarationInfo.defaultProps = {
  setEvaluationPeriods: () => {},
};

RandomGoodsDeclarationInfo.propTypes = {
  randomGoodsDeclaration: PropTypes.shape().isRequired,
  setEvaluationPeriods: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default RandomGoodsDeclarationInfo;
