import React from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Pagination from 'components/base-components/Pagination';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import {
  useGetSupplierCertificateDeclarationsQuery,
  useGetSupplierCertificatesOverviewQuery,
  useGetSupplierCertificateListQuery,
} from 'api/supplier-certificates';
import { APPROVED, COMPLAINED, SUBMITTED } from 'utils/verification-status';
import SUPPLIER_CERTIFICATE_TYPE from 'utils/supplier-certificate-type';
import { useGetCurrentUserQuery } from 'api/users';
import DefaultSpinner from 'components/shared-components/default-spinner';
import { DRAFT } from 'utils/control-body-survey';
import SupplierCertificateTable from './declaration-table.component';
import CreateForm from './create/create.component';
import CurrentDeclarationHeader from './current-declaration/index.component';
import DraftDeclareAction from './draft-declaration/declare-action.component';

const CertificatesList = ({ organizationId, declarationType }) => {
  const [searchParams, setSearchParams] = useSearchParams({ page: 1 });

  let currentPage = parseInt(searchParams.get('page'), 10);

  const { t } = useTranslation();

  const { data: currentUser } = useGetCurrentUserQuery();
  const isAdmin = isBioSuisseAdmin(currentUser);

  const { data: certificatesOverview = {} } = useGetSupplierCertificatesOverviewQuery({
    organizationId,
  });

  const { data: certificateDeclaration } = useGetSupplierCertificateDeclarationsQuery({
    organizationId,
    'status[]': [
      declarationType === SUPPLIER_CERTIFICATE_TYPE.draft
        ? SUPPLIER_CERTIFICATE_TYPE.active
        : SUPPLIER_CERTIFICATE_TYPE.approved,
    ],
  });

  const [certificateDeclarationsResponse] = certificateDeclaration?.declarations || [];
  const { id: certificateDeclarationId } = certificateDeclarationsResponse || {};

  const { isFetching, data: certificateDeclarationsList } = useGetSupplierCertificateListQuery(
    { organizationId, certificateDeclarationId, page: currentPage },
    { skip: !certificateDeclarationId },
  );

  const supplierCertificates = certificateDeclarationsList
    ? certificateDeclarationsList.collection
    : [];

  const totalPages = certificateDeclarationsList
    ? parseInt(certificateDeclarationsList.pagination.totalPages, 10)
    : 0;

  const isDeclared = certificateDeclarationsResponse?.status === SUBMITTED;

  const isApproved = certificateDeclarationsResponse?.status === APPROVED;

  const isComplained = certificateDeclarationsResponse?.status === COMPLAINED;

  const isDraft = certificateDeclarationsResponse?.status === DRAFT;

  const hasDeclaredCertificate = supplierCertificates.some(cert => cert?.declarationDate);
  const shouldBeHidden = isAdmin && !hasDeclaredCertificate;
  const showAlert = certificateDeclarationsResponse?.complaint && !shouldBeHidden;
  const complain = showAlert ? certificateDeclarationsResponse?.complaint : null;

  if (isFetching) {
    return <DefaultSpinner className="default-spinner--center" />;
  } else if (
    !isFetching &&
    !supplierCertificates.length > 0 &&
    declarationType === SUPPLIER_CERTIFICATE_TYPE.draft
  ) {
    return (
      <DraftDeclareAction
        draftCertificateDeclarations={supplierCertificates}
        certificatesOverview={certificatesOverview}
        organizationId={organizationId}
        isComplained={isComplained}
        isApproved={isApproved}
        isDeclared={isDeclared}
        isAdmin={isAdmin}
        isDraft={isDraft}
      />
    );
  }

  return (
    <div>
      {declarationType === SUPPLIER_CERTIFICATE_TYPE.draft ? (
        <DraftDeclareAction
          certificateDeclarationId={certificateDeclarationId}
          organizationId={organizationId}
          isFetching={isFetching}
          draftCertificateDeclarations={supplierCertificates}
          certificatesOverview={certificatesOverview}
          isComplained={isComplained}
          isApproved={isApproved}
          isDeclared={isDeclared}
          isAdmin={isAdmin}
          complain={complain}
          isDraft={isDraft}
        />
      ) : (
        <CurrentDeclarationHeader
          organizationId={organizationId}
          currentCertificateDeclarations={certificateDeclarationsResponse}
        />
      )}
      <SupplierCertificateTable
        organizationId={organizationId}
        supplierCertificates={supplierCertificates}
        certificateDeclarationsResponse={certificateDeclarationsResponse}
      />
      <div
        className={classNames('supplier-certificates__create', {
          'supplier-certificates__create--align-between': !isAdmin,
        })}
      >
        {!isAdmin && declarationType === SUPPLIER_CERTIFICATE_TYPE.draft && (
          <CreateForm organizationId={organizationId} type="default" disabled={isDeclared} />
        )}
        <Pagination
          onPaginationClick={newPage => {
            setSearchParams({ tab: searchParams.get('tab'), page: newPage });
          }}
          totalPages={totalPages}
          currentPage={currentPage}
          nextText={t('pagination.next')}
          previousText={t('pagination.prev')}
          firstText={t('pagination.first')}
          lastText={t('pagination.last')}
        />
      </div>
    </div>
  );
};

CertificatesList.propTypes = {
  organizationId: PropTypes.number.isRequired,
  declarationType: PropTypes.string.isRequired,
};

export default CertificatesList;
