const SUPPLIER_CERTIFICATE_TYPE = {
  draft: 'draft',
  current: 'current',
  expired: 'expired',
  active: 'active',
  approved: 'approved',
  complained: 'complained',
};

export default SUPPLIER_CERTIFICATE_TYPE;
