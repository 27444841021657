import { BlobProvider } from '@react-pdf/renderer';
import {
  useGetAccountingProcessesQuery,
  useGetActiveGoodsDeclarationEvaluationQuery,
  useGetAudioResponsesQuery,
  useGetControlBodyScheduleQuery,
  useGetIngredientReservesQuery,
  useGetLogoAttachmentsQuery,
  useGetLogosQuery,
  useGetRestrictedIngredientOptionsQuery,
  useGetRestrictedIngredientsQuery,
  useGetSurveyReportQuery,
} from 'api/control-body-survey';
import {
  useGetAccountingProcessQuery,
  useGetActiveAccountingProcessesAdditionalTaskQuery,
} from 'api/control-body-survey/accounting-processes';
import { useGetIngredientReservesAdditionalPhotosQuery } from 'api/control-body-survey/ingredient-reserves';
import {
  useGetActiveLogoAttachmentsAdditionalTaskQuery,
  useGetLogoAttachmentPhotosQuery,
} from 'api/control-body-survey/logo-attachments';
import { useGetRestrictedIngredientAdditionalPhotosQuery } from 'api/control-body-survey/restricted-ingredients';
import { useGetOrganizationGoodsQuery } from 'api/goods-declarations';
import { useGetOrganizationQuery } from 'api/organizations';
import { useGetProductsQuery } from 'api/product-declarations';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import Spinner from 'components/base-components/Spinner';
import { TableData } from 'components/base-components/Table';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import Link from 'components/base-components/Link';
import { useParams } from 'react-router-dom';
import bytesToMegabytes from 'utils/bytes-to-megabytes';
import { AVERAGE, BAD } from 'utils/difference-status';
import { DRAFT, FINAL } from 'utils/verification-status';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { useGetUserQuery } from 'api/users';
import CheckListPDFDocument from './checklist-pdf-document';

const ReportPDF = ({ survey, reportType }) => {
  const PDF_FILE_NAME = `${reportType}-control-report.pdf`;
  const { id: organizationId } = useParams();
  const { data: organization } = useGetOrganizationQuery(organizationId);

  const surveyId = survey?.id;
  const { data: reports = {} } = useGetSurveyReportQuery({ surveyId }, { skip: !surveyId });
  const reportData = reportType === DRAFT ? reports.draftReport : reports.finalReport;
  const skip = !surveyId || !reportData;
  const navigate = useCustomNavigate();

  const { data: schedule } = useGetControlBodyScheduleQuery({ surveyId }, { skip });

  // Logo Attachments
  const { data: logoOptions } = useGetLogosQuery();
  const { data: activeLogos } = useGetLogoAttachmentsQuery(
    { surveyId, 'ids[]': reportData?.logoAttachments },
    { skip },
  );

  const { data: auditor = {} } = useGetUserQuery(
    { id: survey.auditorId },
    { skip: !survey.auditorId },
  );

  const { data: logoAttachmentPhotos = [] } = useGetLogoAttachmentPhotosQuery({
    surveyId,
  });

  const { data: ingredientsLogoAttachmentPhotos = [] } =
    useGetIngredientReservesAdditionalPhotosQuery({
      surveyId,
    });

  const { data: restrictedIngredientsAttachmentPhotos = [] } =
    useGetRestrictedIngredientAdditionalPhotosQuery({ surveyId });

  const { data: followUpLogoTask = {} } = useGetActiveLogoAttachmentsAdditionalTaskQuery(
    { surveyId },
    { skip: reportType !== FINAL },
  );
  const followUpLogoAttachmentsPhotos = followUpLogoTask.logos || [];

  const logoAttachmentsData =
    logoOptions &&
    activeLogos &&
    logoOptions.map(labelObj => {
      const attachedObj =
        activeLogos && activeLogos.find(attachedObj => attachedObj.attachedTo === labelObj.value);
      return {
        label: labelObj.label,
        value: labelObj.value,
        attached: attachedObj ? attachedObj.attached : false,
        comment: attachedObj.comment,
      };
    });

  //  Ingredients In Stock
  const { data: activeIngredientsInStock = [] } = useGetIngredientReservesQuery(
    { surveyId, 'ids[]': reportData?.ingredientReserves },
    { skip },
  );

  const productIds = activeIngredientsInStock.map(ingredient => ingredient.productId)

  const { data: products = [] } = useGetProductsQuery({ organizationId, 'ids[]': productIds });

  const productNames = products.reduce((obj, product) => {
    obj[product.id] = product.name
    return obj
  }, {});

  const ingredientsInStockData = activeIngredientsInStock.map(ingredient => {
    return { label: productNames[ingredient.productId], attached: ingredient.inStock };
  })

  // Restricted Ingredients
  const { data: restrictedIngredientOptions = [] } = useGetRestrictedIngredientOptionsQuery();
  const { data: activeRestrictedIngredients } = useGetRestrictedIngredientsQuery(
    { surveyId, 'ids[]': reportData?.restrictedIngredients },
    { skip },
  );

  const restrictedIngredientsData = restrictedIngredientOptions.map(labelObj => {
    const attachedObj =
      activeRestrictedIngredients &&
      activeRestrictedIngredients.find(attachedObj => attachedObj.type === labelObj.value);
    return { label: labelObj.label, exists: attachedObj ? attachedObj.exists : false };
  });

  // Declaration Status
  const declarationDeviations = reportData?.declarationDeviations;

  // Random Goods Declaration
  const { data: goodsDeclarationEvaluation } = useGetActiveGoodsDeclarationEvaluationQuery(
    { surveyId },
    { skip },
  );

  const randomGoodsIds = goodsDeclarationEvaluation?.randomGoodsDeclarations.map(
    item => item.goodsDeclarationId,
  );
  const { data: randomGoods } = useGetOrganizationGoodsQuery(
    { id: organizationId, 'ids[]': randomGoodsIds },
    { skip: !randomGoodsIds },
  );

  const randomGoodsDatas = randomGoods ? randomGoods.goods : [];

  const hasBadRandomDeclarationGood =
    goodsDeclarationEvaluation?.randomGoodsDeclarations &&
    goodsDeclarationEvaluation?.randomGoodsDeclarations?.some(
      good => good.differenceStatus === BAD,
    );
  const hasAverageRandomDeclarationGood =
    !hasBadRandomDeclarationGood &&
    goodsDeclarationEvaluation?.randomGoodsDeclarations &&
    goodsDeclarationEvaluation?.randomGoodsDeclarations?.some(
      good => good.differenceStatus === AVERAGE,
    );
  const hasGoodRandomDeclarationGood =
    goodsDeclarationEvaluation?.randomGoodsDeclarations &&
    goodsDeclarationEvaluation?.randomGoodsDeclarations.length > 0 &&
    !hasBadRandomDeclarationGood &&
    !hasAverageRandomDeclarationGood;

  const requiredDeliveryNotesCount = () => {
    if (hasBadRandomDeclarationGood) return 15;
    else if (hasAverageRandomDeclarationGood) return 10;
    else if (hasGoodRandomDeclarationGood) return 5;

    return 0;
  };

  // Contractual Status
  const contractualStatus = reportData?.declarationStatuses;

  // Accounting Process
  const { data: accountingProcessOptions = [] } = useGetAccountingProcessesQuery();
  const { data: activeAccountingProcess } = useGetAccountingProcessQuery(
    { id: reportData?.accountingProcess },
    { skip },
  );

  const { data: accountingNegativeTask } = useGetActiveAccountingProcessesAdditionalTaskQuery(
    { surveyId },
    { skip: reportType !== FINAL },
  );

  const updatedAccountingTask =
    accountingNegativeTask &&
    accountingProcessOptions &&
    accountingProcessOptions.find(account => account.value === accountingNegativeTask.type);

  const selectedAccountingProcess =
    accountingProcessOptions &&
    activeAccountingProcess &&
    accountingProcessOptions.find(account => account.value === activeAccountingProcess.type);

  // Audio Response
  const { data: activeAudios } = useGetAudioResponsesQuery(
    { surveyId, 'ids[]': reportData?.audioResponses },
    { skip },
  );

  const activeLogosFromAuditor = {};
  const draftReportData = reports.draftReport;
  const logoFromAuditorIds = draftReportData?.logoAttachments;
  const { data: draftAuditorData = {} } = useGetLogoAttachmentsQuery(
    { surveyId, 'ids[]': logoFromAuditorIds },
    { skip: !logoFromAuditorIds || !surveyId },
  );
  Object.keys(draftAuditorData).length > 0 &&
    draftAuditorData?.forEach(
      ({ attachedTo, attached }) => (activeLogosFromAuditor[attachedTo] = attached),
    );

  if (!reportData)
    return (
      <>
        <TableData>{t('common.not_applicable')}</TableData>
        <TableData>
          <div className="choose-file__contract-footer">
            <IconButton
              className="topnav__back-button"
              disabled={true}
              icon={
                <Icon
                  className="choose-file__contract-delete--icon"
                  name="downloadFile"
                  size="normal"
                />
              }
            />
          </div>
        </TableData>
      </>
    );

  return (
    <>
      <BlobProvider
        document={
          <CheckListPDFDocument
            auditorName={auditor?.name}
            organization={organization}
            reportType={reportType}
            survey={survey}
            schedule={schedule}
            comments={reportData.comments}
            logoAttachmentsData={logoAttachmentsData}
            logoAttachmentPhotos={logoAttachmentPhotos}
            followUpLogoAttachmentsPhotos={followUpLogoAttachmentsPhotos}
            ingredientsInStockData={ingredientsInStockData}
            ingredientsLogoAttachmentPhotos={ingredientsLogoAttachmentPhotos}
            restrictedIngredientsAttachmentPhotos={restrictedIngredientsAttachmentPhotos}
            restrictedIngredientsData={restrictedIngredientsData}
            declarationDeviations={declarationDeviations}
            goodsDeclarationEvaluation={goodsDeclarationEvaluation}
            randomGoodsDatas={randomGoodsDatas}
            requiredDeliveryNotesCount={requiredDeliveryNotesCount}
            contractualStatus={contractualStatus}
            accountingProcess={selectedAccountingProcess}
            updatedAccountingTask={updatedAccountingTask}
            audioResponses={activeAudios}
            activeLogosFromAuditor={activeLogosFromAuditor}
          />
        }
      >
        {({ blob, url, loading, error }) => {
          if (loading)
            return (
              <>
                <TableData>
                  <Spinner
                    className="choose-file__contract-footer"
                    bgColor="none"
                    color="success"
                    size="tiny"
                  />
                </TableData>
                <TableData>
                  <Spinner
                    className="choose-file__contract-footer"
                    bgColor="none"
                    color="success"
                    size="tiny"
                  />
                </TableData>
              </>
            );
          const fileSize = blob ? blob.size : 0;

          return (
            <>
              <TableData>
                <div>{bytesToMegabytes(fileSize)} MB</div>
              </TableData>
              <TableData>
                <div className="organisation-contract__download">
                  <div className="choose-file__contract-footer">
                    <IconButton
                      className="topnav__back-button"
                      icon={
                        <Icon
                          className="choose-file__contract-delete--icon"
                          name="show"
                          size="normal"
                        />
                      }
                      disabled={reportType !== FINAL}
                      onClick={() => navigate.organisationViewControlPage(organizationId, surveyId)}
                    />
                    <Link download={PDF_FILE_NAME} href={url}>
                      <IconButton
                        className="topnav__back-button"
                        icon={
                          <Icon
                            className="choose-file__contract-delete--icon"
                            name="downloadFile"
                            size="normal"
                          />
                        }
                      />
                    </Link>
                  </div>
                </div>
              </TableData>
            </>
          );
        }}
      </BlobProvider>
    </>
  );
};

ReportPDF.propTypes = {
  survey: PropTypes.shape().isRequired,
  reportType: PropTypes.string.isRequired,
};

export default ReportPDF;
