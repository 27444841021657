import React from 'react';
import WelcomePageComponent from 'components/welcomepage/welcome-page.component';
import SidenavComponent from 'components/sidenav/sidenav.component';
import TopnavComponent from 'components/topnav/topnav.component';
import { useSelector } from 'react-redux';

const withNavigation = WrappedComponent => {
  const EnhancedPage = props => {
    const user = useSelector(state => state);
    const currentUser = user.api.queries['getCurrentUser(undefined)'];

    if (currentUser) {
      return (
        <>
          <TopnavComponent user={currentUser} />
          <SidenavComponent currentUser={currentUser} />
          <WrappedComponent {...props} />
        </>
      );
    }

    return (
      <WelcomePageComponent showLogoOnly={true}>
        <WrappedComponent {...props} />
      </WelcomePageComponent>
    );
  };

  return EnhancedPage;
};

export default withNavigation;
