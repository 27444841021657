import React from 'react';
import { useLogInMutation } from 'api/session/index';
import { useVerifyTwoFactorAuthenticationMutation } from 'api/two-factor-authentication';
import Alert from 'components/base-components/Alert';
import WelcomePageComponent from 'components/welcomepage/welcome-page.component';
import useCustomNavigate from 'hooks/useCustomNavigate';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { DEACTIVATED } from 'utils/statuses';
import Button from '../base-components/Button';
import Input from '../base-components/Input';
import './login.style.scss';
import VerifyOtp from './verify-otp.component';

export default function LoginComponent() {
  const [userLogIn, { isError, error: { data: errorResponse } = {} }] = useLogInMutation();
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const navigationLinks = useNavigationLinks();
  const [searchParams] = useSearchParams();
  const authError = searchParams.get('error');

  const onUserLogIn = values => {
    userLogIn(values)
      .unwrap()
      .then(() => {
        navigate.openRootPage();
      })
      .catch(() => {});
  };

  const loginError = errorResponse?.invalidCredential || errorResponse?.error;

  if (errorResponse?.enforce2Fa) {
    return navigate.openEnforce2FAPage();
  }

  if (errorResponse?.twoFactorEnabled) {
    return (
      <WelcomePageComponent>
        <VerifyOtp
          onSuccess={navigate.openRootPage}
          verifyMethod={useVerifyTwoFactorAuthenticationMutation}
        />
      </WelcomePageComponent>
    );
  }

  return (
    <WelcomePageComponent>
      {isError && loginError ? (
        <div className="login__form-error">
          <Alert isVisible={isError} hideClose={true} type="danger" isIconVisible={false}>
            {loginError}
          </Alert>
        </div>
      ) : (
        authError && (
          <div className="login__form-error">
            <Alert isVisible={!!authError} hideClose={true} type="warning" isIconVisible={false}>
              {authError === DEACTIVATED
                ? t('shared.errors.deactivated')
                : t('shared.errors.unauthenticated')}
            </Alert>
          </div>
        )
      )}
      <Form
        onSubmit={onUserLogIn}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field name="email">
              {({ input }) => (
                <Input
                  className="login__form-email"
                  label={t('login.email_label')}
                  placeholder={t('login.email_placeholder')}
                  touched={true}
                  error={errorResponse?.errors?.email}
                  {...input}
                />
              )}
            </Field>
            <Field name="password">
              {({ input }) => (
                <Input
                  className="login__form-password"
                  type="password"
                  label={
                    <div className="login__form-password--label">
                      <span>{t('login.password_label')}</span>
                      <Link
                        className="login__form-password--label__forgot-password"
                        to={navigationLinks.forgotPasswordPage()}
                      >
                        {t('login.forgot_password')}
                      </Link>
                    </div>
                  }
                  placeholder={t('login.password_placeholder')}
                  touched={true}
                  error={errorResponse?.errors?.password}
                  data-testid="user-password"
                  {...input}
                />
              )}
            </Field>
            <Button
              label={t('login.sign_in_button')}
              type="success"
              size="normal"
              className="login__button"
              submitType="submit"
            />
          </form>
        )}
      />
    </WelcomePageComponent>
  );
}
