import React, { useState } from 'react';
import SetPasswordForm from 'components/password-management/set-password-form';
import { useResetPasswordMutation } from 'api/password-management';
import { useCheckMFAEnabledQuery, useVerifyResetMFAMutation } from 'api/two-factor-authentication';
import { useSearchParams } from 'react-router-dom';
import DefaultSpinner from 'components/shared-components/default-spinner';
import VerifyOtp from 'components/Login/verify-otp.component';
import WelcomePageComponent from 'components/welcomepage/welcome-page.component';

const ResetPasswordComponent = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const { data, isLoading } = useCheckMFAEnabledQuery({ verify_token: token });

  const [changePassword, { isSuccess }] = useResetPasswordMutation();

  if (isLoading) {
    return <DefaultSpinner />;
  }

  const onOTPVerified = () => {
    setIsOTPVerified(true);
  };

  const isMFAEnabled = data.twoFactorEnabled;

  if (isOTPVerified || !isMFAEnabled) {
    return <SetPasswordForm onSave={changePassword} isSuccess={isSuccess} />;
  }

  return (
    <WelcomePageComponent>
      <VerifyOtp onSuccess={onOTPVerified} verifyMethod={useVerifyResetMFAMutation} />
    </WelcomePageComponent>
  );
};

export default ResetPasswordComponent;
