import {
  useGetSupplierCertificateDeclarationsQuery,
  useGetSupplierCertificateListQuery,
} from 'api/supplier-certificates';
import CheckBox from 'components/base-components/CheckBox';
import Pagination from 'components/base-components/Pagination';
import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/base-components/Table';
import TableLoaderPlaceholder from 'components/shared-components/placeholders/table-loader-placeholder';
import { t } from 'i18next';
import React, { useState } from 'react';
import SUPPLIER_CERTIFICATE_TYPE from 'utils/supplier-certificate-type';
import PropTypes from 'prop-types';

const DeclarationData = ({ selectedIds, setSelectedIds, organizationId }) => {
  const [page, setPage] = useState(1);

  const declarationParams = {
    organizationId,
    'status[]': [SUPPLIER_CERTIFICATE_TYPE.approved, SUPPLIER_CERTIFICATE_TYPE.expired],
  };
  const headers = ['supplier_name', 'certificate_id', 'certification_date', 'declaration_date'];

  const { data } = useGetSupplierCertificateDeclarationsQuery(declarationParams);
  const certificateDeclarationId = data && data.declarations[0]?.id;

  const { data: certificateResponse, isFetching } = useGetSupplierCertificateListQuery(
    { organizationId, certificateDeclarationId, page },
    { skip: !certificateDeclarationId },
  );

  const certificates = certificateResponse?.collection;
  const isAllSelected = certificates?.every(certificate => selectedIds[certificate.id]);

  const onAllCheckboxChange = event => {
    const checked = event.target.checked;
    const newIds = {};
    certificates.forEach(certificate => (newIds[certificate.id] = checked));
    setSelectedIds(prev => ({ ...prev, ...newIds }));
  };

  return (
    <div className="copy-declarations__data">
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>
              <CheckBox isChecked={isAllSelected} size="tiny" onChange={onAllCheckboxChange} />
            </TableHeader>
            {headers.map(column => (
              <TableHeader>{t(`supplier_certificates.attributes.${column}`)}</TableHeader>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isFetching ? (
            <TableLoaderPlaceholder numberOfRows={5} numberOfColumns={5} />
          ) : (
            certificates?.map(certificate => (
              <TableRow>
                <TableData>
                  <CheckBox
                    isChecked={selectedIds[certificate.id]}
                    size="tiny"
                    onChange={event => {
                      setSelectedIds(prev => ({ ...prev, [certificate.id]: event.target.checked }));
                    }}
                  />
                </TableData>
                <TableData>{certificate.name}</TableData>
                <TableData>{certificate.certificationIdentifier}</TableData>
                <TableData>{certificate.certificationDate}</TableData>
                <TableData>{certificate.declarationDate}</TableData>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <Pagination
        className="copy-declarations__pagination"
        onPaginationClick={newPage => setPage(newPage)}
        totalPages={certificateResponse && parseInt(certificateResponse.pagination.totalPages, 10)}
        currentPage={page}
        firstText={t('pagination.first')}
        lastText={t('pagination.last')}
        nextText={t('pagination.next')}
        previousText={t('pagination.prev')}
      />
    </div>
  );
};

DeclarationData.propTypes = {
  selectedIds: PropTypes.array.isRequired,
  setSelectedIds: PropTypes.func.isRequired,
  organizationId: PropTypes.number.isRequired,
};

export default DeclarationData;
