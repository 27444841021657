import Card from 'components/base-components/Card';
import React, { useState } from 'react';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import { LoadingModal } from 'components/shared-components/modal/submit-modal';
import WarningModal from 'components/shared-components/modal/warning';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { useGetActiveControlBodyDeclarationQuery } from 'api/control-body-declarations';
import { Field, Form } from 'react-final-form';
import BioLogo from 'assets/bio_logo.png';
import { Trans, useTranslation } from 'react-i18next';
import {
  useGetLogoAttachmentsQuery,
  useGetActiveGoodsDeclarationEvaluationQuery,
  useGetLogosQuery,
  useGetRestrictedIngredientOptionsQuery,
  useGetAudioResponsesQuery,
  useGetControlBodyScheduleQuery,
  useDeleteAudioMutation,
  useGetSurveyReportQuery,
  useGetSurveyQuery,
} from 'api/control-body-survey';
import {
  useCheckSurveyAssesmentMutation,
  useDraftDeclarationMutation,
  useFinsihDeclarationMutation,
  useSubmitNegativeReportMutation,
} from 'api/control-body-survey';
import Button from 'components/base-components/Button';
import { useGetOrganizationQuery } from 'api/organizations';
import { useGetProductDeclarationsQuery } from 'api/product-declarations';
import { appendListOfFields } from 'utils/form-data';
import Icon from 'components/base-components/Icon';
import { useParams } from 'react-router';
import { useGetCurrentUserQuery } from 'api/users';
import {
  dateMonthYear,
  dateTimeWithZone,
  twentyFourHourTimeFormat,
} from 'utils/date-time-formatter';
import Textarea from 'components/base-components/Textarea/Textarea';
import {
  DRAFT,
  hasPermissionToApproveAdditonalTask,
  hasPermissionToFinshSurvey,
  PERMANENT,
  SUBMITTED,
  TEMPORARY,
} from 'utils/control-body-survey';
import './checklist-form.styles.scss';
import { ACTIVE } from 'utils/verification-status';
import AudioUpload from './options/audio-upload.component';
import LegendWrapper from './options/legend-wrapper.component';
import ConditionalStatus from './options/conditional-status.component';
import LogoIngredientsCheckbox from './options/logo-ingredients-checkbox.component';
import RandomDeclarationComponent from './options/random-declaration.component';
import ReservedIngredients from './options/reserved-ingredients-wrapper.component';
import RestrictedIngredients from './options/restricted-ingredients-wrapper.component';
import AccountingProcess from './options/accouting-process.component';
import ControlSessionDetails from './options/control-session-details.component';

const OrganisationChecklist = () => {
  const { t } = useTranslation();
  const { id: organizationId, surveyId } = useParams();
  const [account, setAccount] = useState(null);
  const [audioDatas, setAudioDatas] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedLogoAttachments, setUploadedLogoAttachments] = useState([]);
  const [uploadedReservedIngredientAttachments, setUploadedReservedIngredientAttachments] =
    useState([]);
  const [uploadedRestrictedIngredientAttachments, setUploadedRestrictedIngredientAttachments] =
    useState([]);
  const [isDraftModalOpen, setIsDraftModalOpen] = useState(false);
  const [isFinishModalOpen, setIsFinishModalOpen] = useState(false);
  const [isNegativeSubmitModalOpen, setIsNegativeSubmitModalOpen] = useState(false);
  const navigate = useCustomNavigate();

  const [errorMessage, setErrorMessage] = useState(null);

  const { data: survey = {} } = useGetSurveyQuery({
    organizationId,
    surveyId,
  });

  const {
    data: controlBodyDeclaration,
    isFetching: isFetchingControlBodyDeclaration,
    isSuccess: isControlBodyDeclarationFetched,
  } = useGetActiveControlBodyDeclarationQuery(organizationId);

  const {
    data: currentUser = {},
    isFetching: isFetchingCurrentUser,
    isSuccess: isCurrentUserFetched,
  } = useGetCurrentUserQuery();

  const [deleteAudio] = useDeleteAudioMutation();
  const { data: organisation } = useGetOrganizationQuery(organizationId);

  const { data: controlBodyScheduleData = {} } = useGetControlBodyScheduleQuery(
    { surveyId },
    { skip: !surveyId },
  );

  const { data: logos } = useGetLogosQuery();
  const { data: activeLogos } = useGetLogoAttachmentsQuery(
    { surveyId, type: ACTIVE },
    { skip: !surveyId },
  );

  const { data: reports = {} } = useGetSurveyReportQuery({ surveyId }, { skip: !surveyId });

  const reportData = reports.draftReport;

  const { data: restrictedIngredients } = useGetRestrictedIngredientOptionsQuery();

  const { data } = useGetProductDeclarationsQuery({
    organizationId,
    type: ACTIVE,
  });

  const itemInStock = data?.collection;

  const { data: activeAudios } = useGetAudioResponsesQuery(
    { surveyId, type: ACTIVE },
    { skip: !surveyId },
  );

  const { data: goodsDeclarationEvaluation } = useGetActiveGoodsDeclarationEvaluationQuery(
    { surveyId },
    { skip: !surveyId },
  );

  const setAudioFile = (questionNumber, file) => {
    if (!file) {
      const filteredAudios = audioDatas.filter(audio => audio.audio_answer !== questionNumber);
      setAudioDatas(filteredAudios);
    } else {
      setAudioDatas(prev => [
        ...prev,
        {
          audio_answer: questionNumber,
          audio: file,
        },
      ]);
    }
  };

  const [
    declareDraft,
    {
      isFetching: isDraftFetching,
      isLoading: isDraftLoading,
      isSuccess: isDraftSuccess,
      isError: isDraftError,
      requestId: draftRequestId,
    },
  ] = useDraftDeclarationMutation();
  const [
    finishDeclaration,
    {
      isSuccess: isFinished,
      isFetching: isFinishFetching,
      isLoading: isFinishLoading,
      isError: isFinishError,
      requestId: finishRequestId,
    },
  ] = useFinsihDeclarationMutation();

  const [
    submitReport,
    {
      isSuccess: isSubmitted,
      isFetching: isSubmitFetching,
      isLoading: isSubmitLoading,
      isError: isSubmitError,
      requestId: submitRequestId,
    },
  ] = useSubmitNegativeReportMutation();

  const [
    checkDeclarationStatus,
    { data: predictedAssessment, isLoading: isCheckingSubmissionStatus },
  ] = useCheckSurveyAssesmentMutation();

  if (isFetchingCurrentUser || isFetchingControlBodyDeclaration) {
    return;
  }

  const controlBodyOrganizationId =
    isControlBodyDeclarationFetched && controlBodyDeclaration.controlBodyOrganizationId;
  const currentUserAssignments = isCurrentUserFetched && currentUser.assignments;

  const isAuditor =
    ['draft', 'initiated'].includes(survey.status) &&
    isCurrentUserFetched &&
    isControlBodyDeclarationFetched &&
    hasPermissionToApproveAdditonalTask(currentUserAssignments, controlBodyOrganizationId);

  const isCertifier =
    [SUBMITTED, TEMPORARY].includes(survey.status) &&
    isCurrentUserFetched &&
    isControlBodyDeclarationFetched &&
    hasPermissionToFinshSurvey(currentUserAssignments, controlBodyOrganizationId);

  const handleAudioDelete = id => {
    deleteAudio({
      audioResponseId: id,
    });
  };

  const onSubmit = (values, form) => {
    const formData = new FormData();
    logos.length > 0 &&
      logos.forEach(item => {
        if (!values[item.value]) return;
        const inStock = values[item.value] || null;
        formData.append('logo_attachments[][attached_to]', item.value);
        if (inStock !== null) {
          formData.append('logo_attachments[][attached]', inStock);
        }
        if (values[`${item.value}__comment`]) {
          formData.append('logo_attachments[][comment]', values[`${item.value}__comment`]);
        }
      });

    if (values.auditorId) {
      formData.append('auditor_id', values.auditorId);
    }

    if (values.controlSessionDate) {
      const { controlSessionDate, controlSessionTime } = values;
      const auditedAt = dateTimeWithZone(`${controlSessionDate} ${controlSessionTime}`);

      formData.append('audited_at', auditedAt);
    }

    if (values.logoComment) {
      formData.append('logo_attachments_comment', values.logoComment);
    }

    if (values.ingredientReservesComment) {
      formData.append('ingredient_reserves_comment', values.ingredientReservesComment);
    }

    if (values.restrictedIngredientsComment) {
      formData.append('restricted_ingredients_comment', values.restrictedIngredientsComment);
    }

    if (values.accountingProcessComment) {
      formData.append('accounting_process_comment', values.accountingProcessComment);
    }

    if (values.audioResponsesComment) {
      formData.append('audio_responses_comment', values.audioResponsesComment);
    }

    uploadedLogoAttachments.forEach(attachment => {
      if (attachment.file) {
        formData.append('logo_attachment_additional_photos[]', attachment.file);
      }
    });

    uploadedReservedIngredientAttachments.forEach(attachment => {
      if (attachment.file) {
        formData.append('ingredient_reserve_additional_photos[]', attachment.file);
      }
    });

    uploadedRestrictedIngredientAttachments.forEach(attachment => {
      if (attachment.file) {
        formData.append('restricted_ingredient_additional_photos[]', attachment.file);
      }
    });

    restrictedIngredients.length > 0 &&
      restrictedIngredients.forEach(item => {
        if (!values[`restricted_${item.value}`]) return;
        const inStock =
          values[`restricted_${item.value}`] !== null
            ? values[`restricted_${item.value}`] === 'yes'
            : null;
        formData.append('restricted_ingredients[][type]', item.value);
        if (inStock !== null) {
          formData.append('restricted_ingredients[][used]', inStock);
        }
      });

    itemInStock?.length > 0 &&
      itemInStock.forEach(item => {
        if (!values[`item_${item.productId}`]) return;
        const inStock =
          values[`item_${item.productId}`] !== null
            ? values[`item_${item.productId}`] === 'yes'
            : null;
        formData.append('ingredient_reserves[][product_id]', item.productId);
        if (inStock !== null) {
          formData.append('ingredient_reserves[][in_stock]', inStock);
        }
      });

    ['one', 'two', 'three'].forEach(number => {
      if (values[`audio_${number}`] !== undefined && values[`audio_${number}`] !== null) {
        const data = audioDatas.find(audio => audio.audio_answer === number);
        const savedData = activeAudios.find(audio => audio.audioAnswer === number);

        if (data?.audio || savedData?.audio) {
          formData.append(`audio_responses[][audio]`, data?.audio || savedData?.audio.url);
          formData.append('audio_responses[][audio_answer]', number);
          formData.append('audio_responses[][satisfied]', values[`audio_${number}`] === 'yes');
        }

        if (values[`audio_${number}_comment`]) {
          formData.append('audio_responses[][comment]', values[`audio_${number}_comment`]);
        }
      }
    });

    goodsDeclarationEvaluation &&
      goodsDeclarationEvaluation.randomGoodsDeclarations.length > 0 &&
      goodsDeclarationEvaluation.randomGoodsDeclarations.forEach(declaration => {
        const customInputData = values[`custom_input_${declaration?.id}`]
          ? values[`custom_input_${declaration?.id}`]
          : declaration?.customInput;

        if (!customInputData) return;

        formData.append(
          `goods_declaration_evaluations[random_declarations][][random_declaration_id]`,
          declaration.id,
        );
        formData.append(
          `goods_declaration_evaluations[random_declarations][][custom_input]`,
          customInputData,
        );
        formData.append(
          'goods_declaration_evaluations[random_selections_comment]',
          values.random_selections_comment,
        );
      });

    uploadedFiles.forEach(uploadedFile => {
      if (!uploadedFile.file) return;
      formData.append('goods_declaration_evaluations[delivery_notes][][file]', uploadedFile.file);
    });

    if (values.auditorAcknowledgement) {
      formData.append(
        'goods_declaration_evaluations[auditor_acknowledgement]',
        values.auditorAcknowledgement === 'yes' ? true : false,
      );
    }

    if (values.deviationAcknowledgement) {
      formData.append('deviation_acknowledgement', values.deviationAcknowledgement === 'yes');
    }

    if (values.certifierAcknowledgement) {
      formData.append(
        'goods_declaration_evaluations[certifier_acknowledgement]',
        values.certifierAcknowledgement === 'yes' ? true : false,
      );
    }

    if (values.certifierComment) {
      formData.append('goods_declaration_evaluations[certifier_comment]', values.certifierComment);
    }

    const formDataMapper = [
      {
        fieldName: 'vat_percentage',
        apiKey: 'goods_declaration_evaluations[vat_percentage]',
        value: values.vat_percentage,
      },
      {
        fieldName: 'vat_percentage_reason',
        apiKey: 'goods_declaration_evaluations[vat_percentage_reason]',
        value: values.vat_percentage_reason || '',
      },
      {
        fieldName: 'comment_to_change_vat_percentage',
        apiKey: 'goods_declaration_evaluations[comment_to_change_vat_percentage]',
        value: values.comment_to_change_vat_percentage,
      },
    ];

    appendListOfFields(form, formData, formDataMapper);
    formData.append(
      'goods_declaration_evaluations[monthly_variations_comment]',
      values.monthlyVariationComment || '',
    );
    formData.append(
      'goods_declaration_evaluations[deadlines_missed_comment]',
      values.deadlinesMissedComment || '',
    );
    formData.append(
      'goods_declaration_evaluations[goods_declarations_value_comment]',
      values.valueOfGoodsComment || '',
    );

    if (values.delivery_notes_comment) {
      formData.append(
        'goods_declaration_evaluations[delivery_notes_comment]',
        values.delivery_notes_comment,
      );
    }

    if (survey?.id) {
      formData.append('survey_id', survey.id);
    }

    if (account) formData.append('accounting_processes[type]', account);

    if (values.contract_acknowledgement_status) {
      formData.append('contract_acknowledgement_status', values.contract_acknowledgement_status);
    }

    if (values.contractual_status_comment) {
      formData.append('contractual_status_comment', values.contractual_status_comment);
    }

    if (values.random_declarations_comment) {
      formData.append('random_declarations_comment', values.random_declarations_comment);
    }

    formData.append('remarks', values.remarks || '');
    handleDeclaration(values.button, formData);
  };

  const handleDeclaration = (buttonValue, payload) => {
    if (buttonValue === 'check') {
      if ([SUBMITTED, TEMPORARY].includes(survey.status) && isCertifier) {
        checkDeclarationStatus({ surveyId: survey.id, payload }).then(() => {
          survey.status === TEMPORARY
            ? setIsNegativeSubmitModalOpen(true)
            : setIsFinishModalOpen(true);
        });
      } else {
        setIsFinishModalOpen(true);
      }
    } else if (buttonValue === 'draft') {
      setIsDraftModalOpen(false);
      return declareDraft({
        surveyId: survey.id,
        payload,
      })
        .unwrap()
        .then(() => setUploadedFiles([]))
        .catch(({ data: { errors } }) => {
          setErrorMessage(errors);
        });
    } else if (buttonValue === 'finish') {
      return finishDeclaration({
        surveyId,
        payload,
      })
        .unwrap()
        .then(() => setIsFinishModalOpen(false))
        .catch(({ data: { errors } }) => {
          setIsFinishModalOpen(false);
          setErrorMessage(errors);
        });
    } else if (buttonValue === 'submit') {
      return submitReport({
        surveyId: survey.id,
      })
        .unwrap()
        .then(() => setIsNegativeSubmitModalOpen(false));
    }
  };

  let confirmModalContent = t('control_body.survey.form.modal.finish.content');

  if (predictedAssessment?.assessmentOutcome === 'positive') {
    confirmModalContent = t('control_body.survey.form.positive_final_report_title');
  } else if (predictedAssessment?.assessmentOutcome === 'negative') {
    confirmModalContent =
      predictedAssessment.status === TEMPORARY
        ? t('control_body.survey.form.provisional_final_report_title')
        : t('control_body.survey.form.negative_final_report_title');
  }

  return (
    <div className="grid col-12 justify-center">
      <div className="col-6">
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <Card className="organisation-checklist">
                <div className="organisation-checklist__alphabet">
                  {t('control_body.survey.form.alphabet_control', { name: organisation?.name })}
                </div>
                <div className="organisation-checklist__date-time">
                  <div className="organisation-checklist__date">
                    <span className="organisation-checklist__date-header">
                      {' '}
                      {t('control_body.survey.form.control_date')}
                    </span>{' '}
                    <span className="organisation-checklist__date-start-time">
                      {controlBodyScheduleData?.startsAt
                        ? dateMonthYear(controlBodyScheduleData?.startsAt)
                        : t('shared.not_available')}
                    </span>
                  </div>
                  <div className="organisation-checklist__time">
                    <span className="organisation-checklist__time-header">
                      {t('control_body.survey.form.time')}
                    </span>{' '}
                    <span className="organisation-checklist__date-start-time">
                      {controlBodyScheduleData?.startsAt
                        ? twentyFourHourTimeFormat(controlBodyScheduleData?.startsAt)
                        : t('shared.not_available')}
                    </span>
                  </div>
                </div>
                <ControlSessionDetails survey={survey} />
                <div className="organisation-checklist__logo">
                  <div className="organisation-checklist__status-wrapper">
                    <div className="organisation-checklist__status-header">
                      <span className="organisation-checklist__number-bold">1 .</span>{' '}
                      <span> {t('control_body.survey.form.first_step')} </span>
                    </div>
                    <div className="organisation-checklist__status-description">
                      {t('control_body.survey.form.first_step_description')}
                    </div>
                  </div>
                  <img
                    className="organisation-checklist__logo-icon"
                    src={BioLogo}
                    alt="Bio-Suisse"
                    height="64"
                    width="64"
                  />
                </div>
                <LogoIngredientsCheckbox
                  items={logos}
                  activeItems={activeLogos}
                  error={errorMessage}
                  logoAttachmentComment={survey?.logoAttachmentsComment}
                  isAuditor={isAuditor}
                  isCertifier={isCertifier}
                  logoFromAuditorIds={reportData?.logoAttachments}
                  surveyId={surveyId}
                  status={survey?.status}
                  form={form}
                  uploadedLogoAttachments={uploadedLogoAttachments}
                  setUploadedLogoAttachments={setUploadedLogoAttachments}
                />

                <ReservedIngredients
                  title={
                    <>
                      <span className="organisation-checklist__number-bold">2 .</span>{' '}
                      <span> {t('control_body.survey.form.second_step')} </span>
                    </>
                  }
                  items={itemInStock}
                  error={errorMessage}
                  comment={survey?.ingredientReservesComment}
                  isAuditor={isAuditor}
                  isCertifier={isCertifier}
                  status={survey?.status}
                  surveyId={surveyId}
                  uploadedReservedIngredientAttachments={uploadedReservedIngredientAttachments}
                  setUploadedReservedIngredientAttachments={
                    setUploadedReservedIngredientAttachments
                  }
                />

                <RestrictedIngredients
                  title={
                    <>
                      <span className="organisation-checklist__number-bold">3 .</span>{' '}
                      <span>{t('control_body.survey.form.third_step')}</span>
                    </>
                  }
                  items={restrictedIngredients}
                  error={errorMessage}
                  comment={survey?.restrictedIngredientsComment}
                  isAuditor={isAuditor}
                  isCertifier={isCertifier}
                  status={survey?.status}
                  surveyId={surveyId}
                  uploadedRestrictedIngredientAttachments={uploadedRestrictedIngredientAttachments}
                  setUploadedRestrictedIngredientAttachments={
                    setUploadedRestrictedIngredientAttachments
                  }
                />
                <LegendWrapper
                  deviationAcknowledgement={survey?.deviationAcknowledgement}
                  isCertifier={isCertifier}
                  errorMessage={errorMessage && errorMessage['deviationAcknowledgement']}
                  surveyStatus={survey?.status}
                  surveyId={surveyId}
                  deadlinesMissedComment={goodsDeclarationEvaluation?.deadlinesMissedComment}
                  monthlyVariationComment={goodsDeclarationEvaluation?.monthlyVariationsComment}
                  valueOfGoodsComment={goodsDeclarationEvaluation?.goodsDeclarationsValueComment}
                />
                <RandomDeclarationComponent
                  goodsDeclarationEvaluation={goodsDeclarationEvaluation}
                  uploadedFiles={uploadedFiles}
                  errorMessage={errorMessage}
                  setUploadedFiles={setUploadedFiles}
                  isAuditor={isAuditor}
                  isCertifier={isCertifier}
                  reportData={reportData}
                  randomDeclarationsComment={survey?.randomDeclarationsComment}
                  status={survey?.status}
                />
                <ConditionalStatus
                  text={
                    <>
                      <span className="organisation-checklist__number-bold">6 .</span>{' '}
                      <span> {t('control_body.survey.form.status')} </span>
                    </>
                  }
                  organizationId={organizationId}
                  surveyDraft={survey}
                  showLine={true}
                  isCertifier={isCertifier}
                  status={survey?.status}
                />

                {errorMessage && errorMessage['contractAcknowledgementStatus'] && (
                  <div className="organisation-checklist__errors">
                    <>
                      <Icon name="invalid" color="danger" size="small" />
                      <span className="organisation-checklist__errors-text">
                        {' '}
                        {errorMessage['contractAcknowledgementStatus']}{' '}
                      </span>
                    </>
                  </div>
                )}
                <div className="organisation-checklist__account">
                  <span className="organisation-checklist__number-bold">7 .</span>{' '}
                  <span> {t('control_body.survey.form.seventh_step')} </span>
                </div>
                <AccountingProcess
                  isCertifier={isCertifier}
                  errorMessage={errorMessage}
                  status={survey?.status}
                  surveyId={surveyId}
                  comment={survey?.accountingProcessComment}
                  setAccount={setAccount}
                  account={account}
                />
                <div className="organisation-checklist__information">
                  <span className="organisation-checklist__number-bold">8 .</span>{' '}
                  <span> {t('control_body.survey.form.eighth_step')} </span>
                </div>
                <div className="organisation-checklist__information-description">
                  {t('control_body.survey.form.audio_header')}
                </div>
                <Card className="organisation-checklist__audio-all">
                  <AudioUpload
                    text={
                      <Trans i18nKey="control_body.survey.form.first_audio_ques_with_details" />
                    }
                    questionNumber="one"
                    setAudioFile={setAudioFile}
                    activeAudio={
                      activeAudios && activeAudios.find(audio => audio.audioAnswer === 'one')
                    }
                    handleAudioDelete={handleAudioDelete}
                    isAuditor={isAuditor}
                    isCertifier={isCertifier}
                    audioIds={reportData?.audioResponses}
                    surveyId={surveyId}
                    status={survey?.status}
                  />
                  <AudioUpload
                    text={
                      <Trans i18nKey="control_body.survey.form.second_audio_ques_with_details" />
                    }
                    questionNumber="two"
                    setAudioFile={setAudioFile}
                    activeAudio={
                      activeAudios && activeAudios.find(audio => audio.audioAnswer === 'two')
                    }
                    handleAudioDelete={handleAudioDelete}
                    isAuditor={isAuditor}
                    isCertifier={isCertifier}
                    audioIds={reportData?.audioResponses}
                    surveyId={surveyId}
                    status={survey?.status}
                  />
                  <AudioUpload
                    text={
                      <Trans i18nKey="control_body.survey.form.third_audio_ques_with_details" />
                    }
                    questionNumber="three"
                    setAudioFile={setAudioFile}
                    activeAudio={
                      activeAudios && activeAudios.find(audio => audio.audioAnswer === 'three')
                    }
                    handleAudioDelete={handleAudioDelete}
                    isAuditor={isAuditor}
                    isCertifier={isCertifier}
                    audioIds={reportData?.audioResponses}
                    surveyId={surveyId}
                    status={survey?.status}
                  />
                  <Field
                    name="audioResponsesComment"
                    initialValue={survey?.audioResponsesComment || ''}
                  >
                    {({ input }) => (
                      <Textarea
                        className="organisation-checklist__quarter-comment"
                        label={
                          isCertifier
                            ? t('control_body.survey.form.auditor_comments')
                            : t('control_body.survey.form.comments')
                        }
                        placeholder={t('control_body.survey.form.comments_placeholder')}
                        disabled={isCertifier || survey?.status === PERMANENT}
                        {...input}
                      />
                    )}
                  </Field>
                  {survey?.status === TEMPORARY && (
                    <div className="organisation-checklist__verify">
                      {t('control_body.survey.form.bio_suisse_verify')}
                    </div>
                  )}
                </Card>
                <div className="organisation-checklist__remarks-wrapper">
                  <hr className="organisation-checklist__checkbox-horizontal-line" />
                  <Field name="remarks" initialValue={survey?.remarks}>
                    {({ input }) => (
                      <Textarea
                        disabled={survey?.remarks}
                        className="organisation-checklist__quarter-comment"
                        label={t('control_body.survey.form.auditor_remarks')}
                        placeholder={t('control_body.survey.form.comments_placeholder')}
                        {...input}
                      />
                    )}
                  </Field>
                </div>
                {errorMessage &&
                  (errorMessage['audioResponses'] ||
                    errorMessage['audio'] ||
                    errorMessage['audioResponsesAudio'] ||
                    errorMessage['audioResponsesReasonOfChange']) && (
                    <div className="organisation-checklist__errors">
                      <>
                        <Icon name="invalid" color="danger" size="small" />
                        <span className="organisation-checklist__errors-text">
                          {errorMessage['audioResponses'] ||
                            errorMessage['audio'] ||
                            errorMessage['audioResponsesAudio'] ||
                            errorMessage['audioResponsesReasonOfChange']}
                        </span>
                      </>
                    </div>
                  )}
                {survey?.status === PERMANENT && (
                  <Button
                    className="organisation-checklist__confirm-button"
                    label={t('shared.action.close')}
                    type="warn"
                    size="small"
                    disabled={survey.status !== PERMANENT}
                    onClick={() => {
                      navigate.openOrganisationControlBodyPage(organizationId);
                    }}
                  />
                )}

                {survey?.status !== PERMANENT && (
                  <div className="organisation-checklist__btn">
                    <Button
                      label={
                        isCertifier
                          ? t('control_flow.button.confirm_report')
                          : t('control_body.survey.form.finish_control')
                      }
                      type="success"
                      size="small"
                      disabled={isFinished || isFinishLoading || isFinishFetching}
                      onClick={() => {
                        form.change('button', 'check');
                        handleSubmit();
                      }}
                    />
                    <Button
                      className="organisation-checklist__btn-save"
                      disabled={survey?.status === TEMPORARY}
                      label={t('control_body.survey.form.save_and_exit')}
                      size="small"
                      onClick={() => {
                        setIsDraftModalOpen(true);
                      }}
                    />
                    <WarningModal
                      isVisible={isDraftModalOpen}
                      onConfirm={() => {
                        form.change('button', DRAFT);
                        handleSubmit();
                      }}
                      onCancel={() => setIsDraftModalOpen(false)}
                      title={t('control_body.survey.form.modal.draft.title')}
                      content={t('control_body.survey.form.modal.draft.content')}
                      confirmActionText={t('common.confirm_button')}
                      cancelActionText={t('shared.action.cancel')}
                    />
                    <LoadingModal isVisible={isCheckingSubmissionStatus} />
                    <WarningModal
                      isVisible={isFinishModalOpen}
                      onConfirm={() => {
                        form.change('button', 'finish');
                        setIsFinishModalOpen(false);
                        handleSubmit();
                      }}
                      onCancel={() => setIsFinishModalOpen(false)}
                      title={t('control_body.survey.form.modal.finish.title')}
                      content={confirmModalContent}
                      confirmActionText={t('control_body.survey.form.modal.finish.confirm_button')}
                      cancelActionText={t('shared.action.cancel')}
                    />
                    <WarningModal
                      isVisible={isNegativeSubmitModalOpen}
                      onConfirm={() => {
                        form.change('button', 'submit');
                        setIsNegativeSubmitModalOpen(false);
                        handleSubmit();
                      }}
                      onCancel={() => setIsNegativeSubmitModalOpen(false)}
                      title={t('control_body.survey.form.modal.negative_submit.title')}
                      content={confirmModalContent}
                      confirmActionText={t('common.confirm_button')}
                      cancelActionText={t('shared.action.cancel')}
                    />
                    <SubmitModal
                      isLoading={isDraftLoading || isDraftFetching}
                      isSuccess={isDraftSuccess}
                      isError={isDraftError}
                      requestId={draftRequestId}
                      loaderContent={t('control_body.survey.form.modal.submit_draft.content')}
                      successContent={t('control_body.survey.form.modal.submit_draft.title')}
                      errorTitle={t('control_body.survey.form.modal.submit_draft.error_message')}
                      onSuccess={() => navigate.openOrganisationControlBodyPage(organizationId)}
                      onFailed={() => setIsDraftModalOpen(false)}
                      showError={true}
                      showLoader={true}
                    />
                    <SubmitModal
                      isLoading={isFinishLoading || isFinishFetching}
                      isSuccess={isFinished}
                      isError={isFinishError}
                      requestId={finishRequestId}
                      loaderContent={t('control_body.survey.form.modal.submit_finished.content')}
                      successTitle={t('control_body.survey.form.modal.submit_finished.title')}
                      errorTitle={t('control_body.survey.form.modal.submit_finished.error_message')}
                      onSuccess={() => navigate.openOrganisationControlBodyPage(organizationId)}
                      onFailed={() => setIsFinishModalOpen(false)}
                      showError={true}
                      showLoader={true}
                    />
                    <SubmitModal
                      isLoading={isSubmitLoading || isSubmitFetching}
                      isSuccess={isSubmitted}
                      isError={isSubmitError}
                      requestId={submitRequestId}
                      loaderContent={t(
                        'control_body.survey.form.modal.submit_finished_final.content',
                      )}
                      successTitle={t('control_body.survey.form.modal.submit_finished_final.title')}
                      errorTitle={t(
                        'control_body.survey.form.modal.submit_finished_final.error_message',
                      )}
                      onSuccess={() => navigate.openOrganisationControlBodyPage(organizationId)}
                      onFailed={() => setIsNegativeSubmitModalOpen(false)}
                      showError={true}
                    />
                  </div>
                )}
              </Card>
            </form>
          )}
        />
      </div>
    </div>
  );
};

export default OrganisationChecklist;
