import { useResetMFAMutation, useVerifyPasswordForMFAMutation } from 'api/users';
import Alert from 'components/base-components/Alert';
import Button from 'components/base-components/Button';
import Input from 'components/base-components/Input';
import TwoFactorConfiguration from 'components/users/credential-management/two-factor/form.component';
import WelcomePageComponent from 'components/welcomepage/welcome-page.component';
import useCustomNavigate from 'hooks/useCustomNavigate';
import { t } from 'i18next';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { useSearchParams } from 'react-router-dom';

const ResetMFAComponent = () => {
  const [verifyPassword, { isSuccess: isPasswordVerified }] = useVerifyPasswordForMFAMutation();
  const navigate = useCustomNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const onSubmitPassword = values => {
    return verifyPassword({ token, password: values.password })
      .unwrap()
      .catch(({ data: { errors } }) => errors);
  };

  if (isPasswordVerified) {
    return (
      <WelcomePageComponent onlyChildren={true}>
        <TwoFactorConfiguration
          onSave={navigate.openLoginPage}
          verifyAndEnableMutation={useResetMFAMutation}
        />
      </WelcomePageComponent>
    );
  }
  return (
    <WelcomePageComponent>
      <span className="forgetpassword__enter-email">{t('mfa_reset.reset_page.message')}</span>
      <Form
        onSubmit={onSubmitPassword}
        render={({ handleSubmit, submitErrors }) => (
          <form onSubmit={handleSubmit}>
            {submitErrors?.base && (
              <div className="login__form-error margin-top-20">
                <Alert isVisible={true} hideClose={true} type="danger" isIconVisible={false}>
                  {submitErrors.base}
                </Alert>
              </div>
            )}
            <Field name="password">
              {({ input, meta }) => (
                <Input
                  {...input}
                  className="login__form-password"
                  type="password"
                  label={
                    <div className="login__form-password--label">
                      <span>{t('login.password_label')}</span>
                    </div>
                  }
                  placeholder={t('login.password_placeholder')}
                  touched={!meta.dirtySinceLastSubmit}
                  error={meta.error || meta.submitError}
                />
              )}
            </Field>
            <Button
              label={t('common.submit')}
              type="success"
              size="normal"
              className="login__button"
              submitType="submit"
            />
          </form>
        )}
      />
    </WelcomePageComponent>
  );
};

export default ResetMFAComponent;
